import React from "react";
import Container from "@mui/material/Container";
import { Box, ContainerProps, useMediaQuery, useTheme } from "@mui/material";

const MaijaContainer: React.FC<ContainerProps> = ({ children, ...otherProps }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Container
      sx={{
        padding: 0,
      }}
      {...otherProps}
    >
      {isMobile && <Box sx={{ mt: 25 }}></Box>}
      {children}
    </Container>
  );
};

export default MaijaContainer;

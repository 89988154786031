import React from "react";
import { Box, Card, Avatar, IconButton, Typography, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import CameraAlt from "@mui/icons-material/CameraAlt";
import Home from "@mui/icons-material/Home";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import WcIcon from "@mui/icons-material/Wc";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { RootState } from "../../store";
import { updateProfilePicture } from "./ProfilePageRepository";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { setProfilePictureUrl } from "../../reducers/userSlice";
import SupportInfoField, { AddressInfo, SupportInfoFieldType } from "./SupportInfoField";
import DisplayedValue from "../../components/DisplayedValue";

const ProfileCard: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const profilePictureUrl = useAppSelector((state: RootState) => state.user.user?.profileIconUrl);
  const supportInfo = useAppSelector((state: RootState) => state.profilePage.supportInfo);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64String = reader.result as string;
        try {
          const profileIconUrl = await updateProfilePicture(base64String);
          dispatch(setProfilePictureUrl(profileIconUrl));
        } catch (error) {
          dispatch(
            showSnackbar({
              message: t("profilePage.supportInfo.saveError", {
                typeLabel: t("profilePage.supportInfo.profilePictureLabel"),
              }),
              severity: "error",
            }),
          );
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Card sx={{ mb: 5, borderRadius: 6, p: isMobile ? 5 : 0 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "left",
          p: 2,
          pb: 5,
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "inline-flex",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <Avatar sx={{ width: 170, height: 170, marginLeft: { xs: 0, md: 7 } }} src={profilePictureUrl || undefined}>
              {!profilePictureUrl && (
                <IconButton sx={{ width: 170, height: 170 }} component="label">
                  <CameraAlt />
                  <input type="file" hidden onChange={handleFileChange} />
                </IconButton>
              )}
            </Avatar>
            {profilePictureUrl && (
              <IconButton
                component="label"
                sx={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  backgroundColor: "white",
                  borderRadius: "50%",
                  width: 40,
                  height: 40,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                  },
                }}
              >
                <CameraAlt />
                <input type="file" hidden onChange={handleFileChange} />
              </IconButton>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            ml: { xs: 0, md: 7 },
            my: 4,
            flexGrow: 1,
            alignItems: isMobile ? "center" : "flex-start",
            textAlign: isMobile ? "center" : "left",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h4">{supportInfo?.name}</Typography>
          </Box>
          {supportInfo?.firstPreferredJob && (
            <Typography variant="subtitle2">{supportInfo.firstPreferredJob}</Typography>
          )}

          <Box
            sx={{
              mt: isMobile ? 4 : 0,
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              flexGrow: 1,
              alignItems: "flex-start",
              textAlign: "left",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1, alignItems: "flex-start" }}>
              <SupportInfoField
                forceSingleRow
                type={SupportInfoFieldType.Address}
                info={{
                  streetName: supportInfo?.streetName ?? "",
                  zipCode: supportInfo?.zipCode ?? "",
                  city: supportInfo?.city ?? "",
                }}
                getUpdatedSupportInfo={(value: AddressInfo) =>
                  supportInfo
                    ? {
                        ...supportInfo,
                        streetName: value.streetName,
                        zipCode: value.zipCode,
                        city: value.city,
                      }
                    : undefined
                }
                leadingSlot={<Home sx={{ mr: isMobile ? 0 : 4, fontSize: 25 }} />}
              />
              <SupportInfoField
                forceSingleRow
                type={SupportInfoFieldType.PhoneNumber}
                info={supportInfo?.phoneNumber || ""}
                getUpdatedSupportInfo={(value: string) =>
                  supportInfo
                    ? {
                        ...supportInfo,
                        phoneNumber: value,
                      }
                    : undefined
                }
                leadingSlot={<LocalPhoneIcon sx={{ mr: isMobile ? 0 : 4, fontSize: 25 }} />}
              />
              <SupportInfoField
                forceSingleRow
                type={SupportInfoFieldType.Gender}
                info={supportInfo?.gender || "OTHER"}
                getUpdatedSupportInfo={(value: string) =>
                  supportInfo
                    ? {
                        ...supportInfo,
                        gender: value,
                      }
                    : undefined
                }
                leadingSlot={<WcIcon sx={{ mr: isMobile ? 0 : 4, fontSize: 25 }} />}
              />

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mt: 2,
                  justifyContent: isMobile ? "center" : "flex-start",
                }}
              >
                <Tooltip title={t("profilePage.supportInfo.emailTooltip")} arrow>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <EmailIcon sx={{ mr: isMobile ? 0 : 4, fontSize: 25 }} />
                    <Typography>{supportInfo?.email}</Typography>
                  </Box>
                </Tooltip>
              </Box>
            </Box>

            {supportInfo?.jobCoachName && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minWidth: 200,
                  flexGrow: 5,
                  mr: 2,
                  pt: isMobile ? 4 : 0,
                  mt: { xs: 2, md: 0 },
                  alignItems: "flex-start",
                  textAlign: "left",
                  mb: -4,
                }}
              >
                <Typography variant="h6">{t("profilePage.supportInfo.jobCoachLabel")}</Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    mt: 2,
                    justifyContent: isMobile ? "center" : "flex-start",
                  }}
                >
                  <Avatar
                    alt={supportInfo?.jobCoachName || undefined}
                    src={supportInfo?.jobCoachProfileIconUrl || undefined}
                    sx={{ width: 50, height: 50, mr: 1 }}
                  />
                  <Box
                    sx={{
                      ml: 2,
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-evenly",
                      alignItems: isMobile ? "center" : "flex-start",
                    }}
                  >
                    <DisplayedValue
                      name={t("profilePage.supportInfo.jobCoachNameLabel")}
                      value={supportInfo?.jobCoachName}
                    />
                    <DisplayedValue
                      name={t("profilePage.supportInfo.jobCoachPhoneLabel")}
                      value={supportInfo?.jobCoachPhoneNumber}
                    />
                    <DisplayedValue
                      name={t("profilePage.supportInfo.jobCoachEmailLabel")}
                      value={supportInfo?.jobCoachEmail}
                    />
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default ProfileCard;

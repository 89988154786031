import { Box, Skeleton, Paper, IconButton, styled, Typography, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { GetApp, Email, Close } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import MaijaModal from "../../../components/MaijaModal";
import { getResumePreview, downloadApplication, emailApplication } from "../../application/ApplicationRepository";
import { showSnackbar } from "../../../reducers/snackbarSlice";
import { useAppDispatch } from "../../../hooks";
import { useTranslation } from "react-i18next";
import { downloadBase64File } from "../../../utils/FileDownloadUtil";

interface ResumePreviewModalProps {
  isOpen: boolean;
  resumeId: string;
  applicationId: string;
  handleClose: () => void;
}

const A4Paper = styled(Paper)(({ theme }) => ({
  width: "100%",
  maxWidth: "600px",
  display: "flex",
  flexDirection: "column",
  boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
  borderRadius: 8,
  marginBottom: theme.spacing(2),
  position: "relative",
  overflow: "hidden",
}));

const ResumePreviewModal: React.FC<ResumePreviewModalProps> = ({ isOpen, resumeId, applicationId, handleClose }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [resumeThumbnails, setResumeThumbnails] = useState<string[]>([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (resumeId && isOpen) {
      setIsLoading(true);
      const fetchResumeThumbnails = async () => {
        try {
          const resumeThumbs = await getResumePreview(resumeId);
          setResumeThumbnails(resumeThumbs);
        } catch (error) {
          dispatch(
            showSnackbar({ message: t("applicationDetails.resumePreviewModal.errorLoading"), severity: "error" }),
          );
          handleClose();
        } finally {
          setIsLoading(false);
        }
      };
      fetchResumeThumbnails();
    }
  }, [isOpen, resumeId, dispatch, t, handleClose]);

  const handleDownload = () => {
    downloadApplication(applicationId)
      .then((data) => data && downloadBase64File(data.resume, `Resume_${applicationId}`))
      .catch(() =>
        dispatch(showSnackbar({ message: t("applicationDetails.page.downloadingError"), severity: "error" })),
      );
  };

  const handleEmail = () => {
    emailApplication(applicationId).catch(() =>
      dispatch(showSnackbar({ message: t("applicationDetails.page.emailingError"), severity: "error" })),
    );
  };

  return (
    <MaijaModal isOpen={isOpen} handleClose={handleClose} width="90%" maxWidth="600px" maxHeight="95vh">
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", p: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", mb: 2 }}>
          <Typography variant="h6">{t("applicationDetails.page.resume")}</Typography>
          <Box>
            <Tooltip title={t("applicationDetails.page.downloadTooltip")}>
              <IconButton onClick={handleDownload}>
                <GetApp />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("applicationDetails.page.emailTooltip")}>
              <IconButton onClick={handleEmail}>
                <Email />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        {isLoading ? (
          <Skeleton
            variant="rounded"
            animation="wave"
            width="100%"
            height={isMobile ? "60vh" : "80vh"}
            sx={{ maxWidth: 600 }}
          />
        ) : (
          resumeThumbnails.map((thumbnail, index) => (
            <A4Paper key={index}>
              <Tooltip title={t("applicationDetails.page.closeTooltip")}>
                <IconButton
                  onClick={handleClose}
                  sx={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 1)",
                    },
                    padding: 0.5,
                  }}
                >
                  <Close />
                </IconButton>
              </Tooltip>

              <img
                src={`data:image/jpeg;base64,${thumbnail}`}
                alt={`Resume Page ${index + 1}`}
                style={{ width: "100%", height: "auto", borderRadius: "8px" }}
              />
            </A4Paper>
          ))
        )}
      </Box>
    </MaijaModal>
  );
};

export default ResumePreviewModal;

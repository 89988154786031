let API_BASE_URL: string;

if (process.env.REACT_APP_API_BASE_URL === undefined || process.env.REACT_APP_API_BASE_URL === "") {
  API_BASE_URL = "https://maija-api.azurewebsites.net/v1/applicant";
} else {
  API_BASE_URL = process.env.REACT_APP_API_BASE_URL ?? "";
}

const APP_VERSION = process.env.REACT_APP_VERSION ?? "Unknown";

// force local api
//API_BASE_URL = "http://127.0.0.1:5001/v1/applicant"

export { API_BASE_URL, APP_VERSION };

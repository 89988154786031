import React, { useEffect, useState, useMemo } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  ListItem,
  ListItemText,
} from "@mui/material";
import { addLanguage, setSupportInfo, updateLanguage } from "../../reducers/profilePageSlice";
import { submitLanguage, updateLanguage as apiUpdateLanguage, updateSupportInfo } from "./ProfilePageRepository";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Language } from "../../types/Language";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { useTranslation } from "react-i18next";
import allLanguages from "../../data/languages.json";
import { RootState } from "../../store";

interface LanguageModalProps {
  initialLanguage: Language | null;
  open: boolean;
  onClose: () => void;
}

interface ProficiencyOption {
  value: string;
  label: string;
}

interface LanguageOption {
  code: string;
  label: string;
}

interface LanguageJSON {
  name: string;
  code: string;
}

const LanguageModal: React.FC<LanguageModalProps> = ({ open, onClose, initialLanguage }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userLanguages = useAppSelector((state: RootState) => state.profilePage.languages ?? []);

  const [selectedLanguage, setSelectedLanguage] = useState<LanguageOption | null>(null);
  const [selectedProficiency, setSelectedProficiency] = useState<string>("");

  const languageOptions = useMemo(() => {
    return (allLanguages as LanguageJSON[]).map((language) => ({
      code: language.code,
      label: t(`languages.${language.code}`),
    }));
  }, [t]);

  const defaultProficiencyOptions = useMemo(
    () => [
      { value: "MOTHER_TONGUE", label: t("profilePage.language.proficiencyNative") },
      { value: "FLUENT", label: t("profilePage.language.proficiencyFluent") },
      { value: "ADVANCED", label: t("profilePage.language.proficiencyAdvanced") },
      { value: "INTERMEDIATE", label: t("profilePage.language.proficiencyIntermediate") },
      { value: "BASIC", label: t("profilePage.language.proficiencyBasic") },
    ],
    [t],
  );

  const swedishProficiencyOptions = useMemo(
    () => [
      { value: "MOTHER_TONGUE", label: t("profilePage.language.proficiencyNative") },
      { value: "A", label: "SFI A" },
      { value: "B", label: "SFI B" },
      { value: "C", label: "SFI C" },
      { value: "D", label: "SFI D" },
      { value: "SAS_G", label: "SAS G" },
    ],
    [t],
  );

  const [proficiencyOptions, setProficiencyOptions] = useState<ProficiencyOption[]>(defaultProficiencyOptions);

  useEffect(() => {
    if (open) {
      if (initialLanguage) {
        const matchingLanguageOption = languageOptions.find((option) => option.code === initialLanguage.code);
        if (matchingLanguageOption) {
          setSelectedLanguage(matchingLanguageOption);
          if (matchingLanguageOption.code === "sv") {
            setProficiencyOptions(swedishProficiencyOptions);
          } else {
            setProficiencyOptions(defaultProficiencyOptions);
          }
        }
        setSelectedProficiency(initialLanguage.proficiency || "");
      } else {
        resetFields();
      }
    }
  }, [open, initialLanguage, languageOptions, defaultProficiencyOptions, swedishProficiencyOptions]);

  const resetFields = () => {
    setSelectedLanguage(null);
    setSelectedProficiency("");
    setProficiencyOptions(defaultProficiencyOptions);
  };

  const handleLanguageSelect = (language: LanguageOption) => {
    setSelectedLanguage(language);
    if (language.code === "sv") {
      setProficiencyOptions(swedishProficiencyOptions);
    } else {
      setProficiencyOptions(defaultProficiencyOptions);
    }
  };

  const handleSave = async () => {
    if (selectedLanguage && selectedProficiency) {
      const isDuplicate = userLanguages.some(
        (lang) => lang.code === selectedLanguage.code && (initialLanguage ? lang.id !== initialLanguage.id : true),
      );

      if (isDuplicate) {
        dispatch(
          showSnackbar({
            message: t("profilePage.language.duplicateError", {
              language: selectedLanguage.label,
            }),
            severity: "error",
          }),
        );
        return;
      }

      try {
        if (initialLanguage) {
          const languageData = {
            id: initialLanguage.id,
            code: selectedLanguage.code,
            name:
              (allLanguages as LanguageJSON[]).find((lang) => lang.code === selectedLanguage.code)?.name ||
              selectedLanguage.label,
            proficiency: selectedProficiency,
          };
          const updatedLanguage = await apiUpdateLanguage(languageData);
          dispatch(updateLanguage(updatedLanguage));
        } else {
          const languageData = {
            code: selectedLanguage.code,
            name:
              (allLanguages as LanguageJSON[]).find((lang) => lang.code === selectedLanguage.code)?.name ||
              selectedLanguage.label,
            proficiency: selectedProficiency,
          };
          const newLanguage = await submitLanguage(languageData);
          dispatch(addLanguage(newLanguage));
        }

        if (selectedLanguage.code === "sv") {
          try {
            const updatedSupportInfo = await updateSupportInfo({ sfiLevel: selectedProficiency });
            dispatch(setSupportInfo(updatedSupportInfo));
          } catch (error) {
            dispatch(
              showSnackbar({
                message: t("profilePage.supportInfo.saveError", {
                  typeLabel: t("profilePage.supportInfo.sfiLevelLabel"),
                }),
                severity: "error",
              }),
            );
          }
        }

        dispatch(
          showSnackbar({
            message: initialLanguage ? t("profilePage.language.updateSuccess") : t("profilePage.language.addSuccess"),
            severity: "success",
          }),
        );

        onClose();
        resetFields();
      } catch (error) {
        dispatch(
          showSnackbar({
            message: initialLanguage ? t("profilePage.language.updateError") : t("profilePage.language.addError"),
            severity: "error",
          }),
        );
      }
    }
  };

  const availableLanguageOptions = useMemo(() => {
    const addedLanguages = new Set(userLanguages.map((lang) => lang.code.toLowerCase()));
    if (initialLanguage) {
      addedLanguages.delete(initialLanguage.code.toLowerCase());
    }
    return languageOptions.filter((option) => !addedLanguages.has(option.code.toLowerCase()));
  }, [languageOptions, userLanguages, initialLanguage]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
        resetFields();
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        {initialLanguage ? t("profilePage.language.editTitle") : t("profilePage.language.addTitle")}
      </DialogTitle>

      <DialogContent sx={{ overflow: "visible" }}>
        {!initialLanguage ? (
          <Autocomplete
            options={availableLanguageOptions}
            getOptionLabel={(option) => option.label}
            value={selectedLanguage}
            onChange={(event, newValue) => {
              if (newValue) {
                handleLanguageSelect(newValue);
              } else {
                setSelectedLanguage(null);
              }
            }}
            renderInput={(params) => (
              <TextField {...params} label={t("profilePage.language.selectPlaceholder")} variant="outlined" fullWidth />
            )}
            noOptionsText={
              availableLanguageOptions.length === 0
                ? t("profilePage.language.noAvailableOptions") || "All languages have been added."
                : "No matches found."
            }
            renderOption={(props, option) => (
              <ListItem {...props} key={option.code}>
                <ListItemText primary={option.label} />
              </ListItem>
            )}
          />
        ) : (
          <TextField
            value={t(`languages.${selectedLanguage?.code}`) || ""}
            variant="outlined"
            fullWidth
            label={t("profilePage.language.selectPlaceholder")}
            disabled
          />
        )}

        {selectedLanguage && (
          <FormControl fullWidth sx={{ marginTop: 2 }}>
            <InputLabel>{t("profilePage.language.selectProficiencyPlaceholder")}</InputLabel>
            <Select
              value={selectedProficiency}
              onChange={(event) => setSelectedProficiency(event.target.value as string)}
              label={t("profilePage.language.selectProficiencyPlaceholder")}
            >
              {proficiencyOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
            resetFields();
          }}
          color="primary"
        >
          {t("generic.cancel")}
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          variant="contained"
          disabled={!selectedLanguage || !selectedProficiency}
          data-cy="save-button"
        >
          {initialLanguage ? t("profilePage.language.saveChangesButton") : t("profilePage.language.addButton")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LanguageModal;

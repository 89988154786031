import { NavigateFunction } from "react-router-dom";
import LoginPage from "./pages/login/LoginPage";
import ProfilePage from "./pages/profile/ProfilePage";
import ManageAccountPage from "./pages/profile/ProfilePage";
import ChangePasswordPage from "./pages/change-password/ChangePasswordPage";
import ForgotPasswordPage from "./pages/forgot-password/ForgotPasswordPage";
import ResetPasswordPage from "./pages/reset-password/ResetPasswordPage";
import ApplicationPage from "./pages/application/ApplicationPage";
import ApplicationDetailsPage from "./pages/application-details/ApplicationDetailsPage";
import ApplicationsListPage from "./pages/applications-list/ApplicationsListPage";
import { AccountCircle } from "@mui/icons-material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DraftsIcon from "@mui/icons-material/Drafts";
import ApplicantCalendar from "./pages/calendar/ApplicantCalendar";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

interface MaijaRoute {
  icon: JSX.Element | undefined;
  path: string;
  nameKey: string;
  component: React.ComponentType;
  requireApplicationDetails?: boolean;
}

export const LOGIN_ROUTE = {
  path: "/login",
  nameKey: "route.login",
  component: LoginPage,
  icon: undefined,
  requireApplicationDetails: false,
};

export const PROFILE_ROUTE = {
  path: "/profile",
  nameKey: "route.profile",
  component: ProfilePage,
  icon: <AccountCircle />,
  requireApplicationDetails: false,
};

export const APPLICATION_ROUTE = {
  path: "/application",
  nameKey: "route.application",
  component: ApplicationPage,
  icon: <ArrowForwardIosIcon />,
  requireApplicationDetails: false,
};

export const APPLICATION_DETAILS_ROUTE = {
  path: "/application/details/:id",
  nameKey: "route.application_details",
  component: ApplicationDetailsPage,
  icon: undefined,
  requireApplicationDetails: true,
};

export const APPLICATION_LIST_ROUTE = {
  path: "/applications-list",
  nameKey: "route.application_list",
  component: ApplicationsListPage,
  icon: <DraftsIcon />,
  requireApplicationDetails: false,
};

export const APPLICANT_CALENDAR_ROUTE = {
  path: "/calendar",
  nameKey: "route.calendar",
  component: ApplicantCalendar,
  icon: <CalendarMonthIcon />,
  requireApplicationDetails: false,
};

export const MANAGE_ACCOUNT_ROUTE = {
  path: "/manage",
  nameKey: "route.manage_account",
  component: ManageAccountPage,
  icon: undefined,
  requireApplicationDetails: false,
};

export const FORGOT_PASSWORD_ROUTE = {
  path: "/forgot-password",
  nameKey: "route.forgot_password",
  component: ForgotPasswordPage,
  icon: undefined,
  requireApplicationDetails: false,
};

export const CHANGE_PASSWORD_ROUTE = {
  path: "/change-password",
  nameKey: "route.change_password",
  component: ChangePasswordPage,
  icon: undefined,
  requireApplicationDetails: false,
};

export const RESET_PASSWORD_ROUTE = {
  path: "/reset-password",
  nameKey: "route.reset_password",
  component: ResetPasswordPage,
  icon: undefined,
};

export const maijaRoutes: MaijaRoute[] = [
  PROFILE_ROUTE,
  APPLICATION_ROUTE,
  APPLICATION_DETAILS_ROUTE,
  APPLICANT_CALENDAR_ROUTE,
  APPLICATION_LIST_ROUTE,
  MANAGE_ACCOUNT_ROUTE,
  CHANGE_PASSWORD_ROUTE,
];

export function getMaijaRoutes(): MaijaRoute[] {
  return maijaRoutes;
}

export function navigateToApplicationDetails(navigateFunction: NavigateFunction, id: string) {
  navigateFunction(`/application/details/${id}`);
}

export default MaijaRoute;

import React, { useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { addEducation, updateEducation } from "../../reducers/profilePageSlice";
import { submitEducation, updateEducation as apiUpdateEducation } from "./ProfilePageRepository";
import { useAppDispatch } from "../../hooks";
import { Education } from "../../types/Education";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { Box } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

interface EducationModalProps {
  initialEducation: Education | null;
  open: boolean;
  onClose: () => void;
}

const EducationModal: React.FC<EducationModalProps> = ({ open, onClose, initialEducation }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [subject, setSubject] = useState(initialEducation?.subject ?? "");
  const [provider, setProvider] = useState(initialEducation?.provider ?? "");
  const [category, setCategory] = useState(initialEducation?.category ?? "");
  const [fromDate, setFromDate] = useState(initialEducation?.fromDate ? dayjs(initialEducation.fromDate) : null);
  const [toDate, setToDate] = useState(initialEducation?.toDate ? dayjs(initialEducation.toDate) : null);
  const [description, setDescription] = useState(initialEducation?.description ?? "");
  const [isEditMode, setIsEditMode] = useState(initialEducation !== null);
  const [isCurrentEducation, setIsCurrentEducation] = useState(initialEducation?.toDate === null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setSubject(initialEducation?.subject ?? "");
    setProvider(initialEducation?.provider ?? "");
    setCategory(initialEducation?.category ?? "");
    setFromDate(initialEducation?.fromDate ? dayjs(initialEducation.fromDate) : null);
    setToDate(initialEducation?.toDate ? dayjs(initialEducation.toDate) : null);
    setDescription(initialEducation?.description ?? "");
    setIsEditMode(initialEducation !== null);
    setIsCurrentEducation(initialEducation?.toDate === null);
  }, [initialEducation]);

  useEffect(() => {
    setTimeout(() => {
      if (open && inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  }, [open]);

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (
      event.key === "Enter" &&
      subject.trim() &&
      provider.trim() &&
      category.trim() &&
      fromDate &&
      dayjs(fromDate).isValid() &&
      (isCurrentEducation || (toDate && dayjs(toDate).isValid()))
    ) {
      handleSave();
      event.preventDefault();
    } else if (event.key === "Escape") {
      if (isEditMode) {
        onClose();
      } else {
        clearData();
      }
    }
  };

  const clearData = () => {
    setSubject("");
    setProvider("");
    setCategory("");
    setFromDate(null);
    setToDate(null);
    setDescription("");
    setIsCurrentEducation(false);
    onClose();
  };

  const handleSave = () => {
    if (!isCurrentEducation && fromDate && toDate && fromDate.isAfter(toDate)) {
      dispatch(
        showSnackbar({
          message: t("profilePage.education.dateRangeError"),
          severity: "error",
        }),
      );
      return;
    }
    if (initialEducation) {
      const updatedEducation: Education = {
        id: initialEducation.id!,
        subject,
        provider,
        category,
        fromDate: fromDate!.toISOString(),
        toDate: isCurrentEducation ? null : toDate?.toISOString() || null,
        description,
      };

      handleUpdateEducation(updatedEducation);
    } else {
      handleAddEducation({
        subject,
        provider,
        category,
        fromDate: fromDate!.toISOString(),
        toDate: isCurrentEducation ? null : toDate?.toISOString() || null,
        description,
      });
    }
    clearData();
  };

  const handleAddEducation = async (newEducation: Omit<Education, "id">) => {
    try {
      const newEducationData = await submitEducation(newEducation);
      dispatch(addEducation(newEducationData));
    } catch (error) {
      dispatch(showSnackbar({ message: t("profilePage.education.addError"), severity: "error" }));
    }
  };

  const handleUpdateEducation = async (updatedEducation: Education) => {
    try {
      const updatedEducationData = await apiUpdateEducation(updatedEducation);
      dispatch(updateEducation(updatedEducationData));
    } catch (error) {
      dispatch(showSnackbar({ message: t("profilePage.education.updateError"), severity: "error" }));
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Dialog open={open} onClose={isEditMode ? onClose : clearData} fullWidth={true}>
        <DialogTitle>
          {isEditMode ? t("profilePage.education.editTitle") : t("profilePage.education.addTitle")}
        </DialogTitle>
        <DialogContent>
          <TextField
            inputRef={inputRef}
            autoFocus
            margin="dense"
            label={t("profilePage.education.subjectLabel")}
            type="text"
            fullWidth
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            onKeyDown={handleKeyPress}
            data-cy="subject"
          />
          <TextField
            margin="dense"
            label={t("profilePage.education.providerLabel")}
            type="text"
            fullWidth
            value={provider}
            onChange={(e) => setProvider(e.target.value)}
            onKeyDown={handleKeyPress}
            data-cy="provider"
          />
          <TextField
            margin="dense"
            label={t("profilePage.education.categoryLabel")}
            type="text"
            fullWidth
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            onKeyDown={handleKeyPress}
            data-cy="category"
          />

          <Box sx={{ my: 4, display: "flex" }}>
            <DatePicker
              sx={{ mr: 2, flexGrow: 1 }}
              label={t("profilePage.education.fromDateLabel")}
              value={fromDate}
              onChange={(date) => setFromDate(date)}
              maxDate={toDate || dayjs()}
              format="YYYY-MM-DD"
              slotProps={{
                textField: {
                  error: false,
                  inputProps: {
                    "data-cy": "from-date",
                  },
                },
              }}
            />

            <DatePicker
              sx={{ flexGrow: 1 }}
              label={t("profilePage.education.toDateLabel")}
              value={toDate}
              minDate={fromDate}
              format="YYYY-MM-DD"
              onChange={(date) => setToDate(date)}
              disabled={isCurrentEducation}
              slotProps={{
                textField: {
                  error: false,
                  inputProps: {
                    "data-cy": "to-date",
                  },
                },
              }}
            />
          </Box>

          <FormControlLabel
            control={
              <Checkbox
                checked={isCurrentEducation}
                onChange={(e) => setIsCurrentEducation(e.target.checked)}
                data-cy="current-education"
              />
            }
            label={t("profilePage.education.currentEducationLabel")}
          />

          <TextField
            margin="dense"
            label={t("profilePage.education.descriptionLabel")}
            type="text"
            fullWidth
            multiline
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            onKeyDown={handleKeyPress}
            data-cy="description"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={isEditMode ? onClose : clearData} color="primary">
            {t("generic.cancel")}
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            disabled={
              !subject.trim() ||
              !provider.trim() ||
              !category.trim() ||
              !fromDate ||
              !dayjs(fromDate).isValid() ||
              (!isCurrentEducation && (!toDate || !dayjs(toDate).isValid()))
            }
            data-cy="save-button"
          >
            {isEditMode ? t("profilePage.education.saveChangesButton") : t("profilePage.education.addButton")}
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default EducationModal;

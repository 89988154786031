import { ProfilePageState } from "../../reducers/profilePageSlice";
import { ApplicantSupportInfo } from "../../types/ApplicationSupportInfo";
import { Education } from "../../types/Education";
import { Language } from "../../types/Language";
import { SelfAssessmentQuestion } from "../../types/SelfAssessment";
import { Skill } from "../../types/Skill";
import { WorkExperience } from "../../types/WorkExperience";

export function workExperiencesCompletion(workExperiences?: WorkExperience[]): number {
  return workExperiences && workExperiences.length > 0 ? 1 : 0;
}

export function educationsCompletion(educations?: Education[]): number {
  return educations && educations.length > 0 ? 1 : 0;
}

export function skillsCompletion(skills?: Skill[]): number {
  return skills && skills.length > 0 ? 1 : 0;
}

export function languagesCompletion(languages?: Language[]): number {
  if (!languages || languages.length === 0) {
    return 0;
  }

  const allHaveProficiency = languages.every((lang) => lang.proficiency && lang.proficiency.trim().length > 0);

  return allHaveProficiency ? 1 : 0;
}

export function selfAssessmentCompletion(questions?: SelfAssessmentQuestion[]): number {
  if (!questions || questions.length === 0) return 0;

  const answeredQuestions = questions.filter((question) => question.answer !== null).length;
  return answeredQuestions / questions.length;
}

export function supportInfoCompletion(supportInfo: ApplicantSupportInfo | null | undefined): number {
  if (!supportInfo) return 0;

  const fieldsToCheck = [
    supportInfo.firstPreferredJob,
    supportInfo.secondPreferredJob,
    supportInfo.thirdPreferredJob,
    supportInfo.desiredJobInFiveYears,
    supportInfo.streetName,
    supportInfo.zipCode,
    supportInfo.city,
    supportInfo.partTimePercentage !== undefined ? supportInfo.partTimePercentage : null,
  ];

  const filledFields = fieldsToCheck.filter((field) => field !== null && field !== undefined).length;
  const totalFields = fieldsToCheck.length;

  return filledFields / totalFields;
}

export function introductionCompletion(supportInfo: ApplicantSupportInfo | null | undefined): number {
  if (!supportInfo) return 0;
  if (supportInfo.description == null) return 0;
  return supportInfo.description.length > 0 ? 1 : 0;
}

export function driversLicenseCompletion(state: ProfilePageState): number {
  const hasLicense = state.driversLicenses && state.driversLicenses.length > 0;

  return hasLicense ? 1 : 0;
}

export function calculateCompletionPercentage(state: ProfilePageState): number {
  const totalSections = 8;
  const workExperiencesScore = workExperiencesCompletion(state.workExperiences);
  const educationsScore = educationsCompletion(state.educations);
  const skillsScore = skillsCompletion(state.skills);
  const languagesScore = languagesCompletion(state.languages);
  const selfAssessmentScore = selfAssessmentCompletion(state.selfAssessmentQuestions);
  const supportInfoScore = supportInfoCompletion(state.supportInfo);
  const introductionScore = introductionCompletion(state.supportInfo);
  const driversLicenseScore = driversLicenseCompletion(state);

  const completedSections =
    workExperiencesScore +
    educationsScore +
    skillsScore +
    languagesScore +
    selfAssessmentScore +
    supportInfoScore +
    introductionScore +
    driversLicenseScore;

  return (completedSections / totalSections) * 100;
}

export function profileDataIsPrestineLoading(state: ProfilePageState): boolean {
  return (
    state.workExperiences === undefined ||
    state.educations === undefined ||
    state.skills === undefined ||
    state.languages === undefined ||
    state.selfAssessmentQuestions === undefined ||
    state.supportInfo === undefined
  );
}

import React, { useEffect, useState } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import { EventInput, EventContentArg } from "@fullcalendar/core";
import { useTranslation } from "react-i18next";
import { Meeting, MeetingType } from "../../types/Meeting";
import { SendOutTime } from "../../types/SendOutTime";
import { fetchUserMeetings, fetchUserSendOutTimes } from "./ApplicantCalendarRepository";
import MaijaContainer from "../../components/MaijaContainer";

type ApplicantCalendarProps = object;

export const ApplicantCalendar: React.FC<ApplicantCalendarProps> = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [meetings, setMeetings] = useState<Meeting[]>([]);
  const [sendOutTimes, setSendOutTimes] = useState<SendOutTime[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const loadEvents = async () => {
      try {
        const userMeetings = await fetchUserMeetings();
        const userSendOutTimes = await fetchUserSendOutTimes();
        setMeetings(userMeetings);
        setSendOutTimes(userSendOutTimes);
      } catch (error) {
        console.error("Error fetching user's calendar data:", error);
      } finally {
        setLoading(false);
      }
    };
    loadEvents();
  }, []);

  const monthNamesLong = [
    t("calendar.monthNames.january"),
    t("calendar.monthNames.february"),
    t("calendar.monthNames.march"),
    t("calendar.monthNames.april"),
    t("calendar.monthNames.may"),
    t("calendar.monthNames.june"),
    t("calendar.monthNames.july"),
    t("calendar.monthNames.august"),
    t("calendar.monthNames.september"),
    t("calendar.monthNames.october"),
    t("calendar.monthNames.november"),
    t("calendar.monthNames.december"),
  ];

  const monthNamesShort = [
    t("calendar.monthNamesShort.jan"),
    t("calendar.monthNamesShort.feb"),
    t("calendar.monthNamesShort.mar"),
    t("calendar.monthNamesShort.apr"),
    t("calendar.monthNamesShort.may"),
    t("calendar.monthNamesShort.jun"),
    t("calendar.monthNamesShort.jul"),
    t("calendar.monthNamesShort.aug"),
    t("calendar.monthNamesShort.sep"),
    t("calendar.monthNamesShort.oct"),
    t("calendar.monthNamesShort.nov"),
    t("calendar.monthNamesShort.dec"),
  ];

  const events: EventInput[] = [];

  const parseDate = (dateStr: string): string => {
    const date = new Date(dateStr);
    return date.toISOString();
  };

  const formatTime = (dateStr: string): string => {
    const date = new Date(dateStr);
    return date.toLocaleTimeString("se-SE", { hour: "2-digit", minute: "2-digit" });
  };

  meetings.forEach((meeting) => {
    events.push({
      title: meeting.jobCoachFullName,
      start: parseDate(meeting.startTime),
      end: parseDate(meeting.endTime),
      color: "mediumturquoise",
      extendedProps: {
        meetingType: meeting.type,
        description: `${meeting.jobCoachFullName}\n${
          meeting.type === MeetingType.Physical ? t("calendar.physicalMeeting") : t("calendar.virtualMeeting")
        }\n${formatTime(meeting.startTime)} - ${formatTime(meeting.endTime)}`,
      },
    });
  });

  sendOutTimes.forEach((sendOut) => {
    events.push({
      title: `${sendOut.jobCoachFullName} - ${t("calendar.sendout")}: ${sendOut.sendOut.title}`,
      start: parseDate(sendOut.sendTimeStart),
      end: sendOut.sendTimeEnd ? parseDate(sendOut.sendTimeEnd) : parseDate(sendOut.sendTimeStart),
      color: "lightgreen",
      extendedProps: {
        description: `${t("calendar.applicant")}: ${sendOut.jobCoachFullName}\n${t("calendar.sendout")}: ${
          sendOut.sendOut.title
        }\n${t("common.time")}: ${formatTime(sendOut.sendTimeStart)} - ${formatTime(
          sendOut.sendTimeEnd || sendOut.sendTimeStart,
        )}`,
      },
    });
  });

  const eventStyle: React.CSSProperties = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  if (loading) {
    return <Box>{t("common.loading")}</Box>;
  }

  return (
    <MaijaContainer>
      <Box
        sx={{
          pr: 4,
          mt: 5,
          mx: { xs: 0, md: 5 },
          height: { xs: "100vh", md: "auto" },
          minHeight: { md: "600px" },
          display: "flex",
          flexDirection: "column",
          "& .fc-toolbar": {
            flexWrap: "wrap",
            overflowX: "auto",
          },
          "& .fc .fc-button": {
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            flexShrink: 0,
          },
          "& .fc .fc-button:hover": {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.contrastText,
          },
          "& .fc .fc-button.fc-button-active": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          },
        }}
      >
        <FullCalendar
          plugins={[timeGridPlugin, dayGridPlugin]}
          initialView={isMobile ? "timeGridDay" : "timeGridWeek"}
          firstDay={1}
          events={events}
          slotDuration="00:30:00"
          slotLabelFormat={{
            hour: "numeric",
            minute: "2-digit",
            hour12: false,
          }}
          eventTimeFormat={{
            hour: "numeric",
            minute: "2-digit",
            hour12: false,
          }}
          businessHours={{
            daysOfWeek: [1, 2, 3, 4, 5],
            startTime: "08:00",
            endTime: "17:00",
          }}
          allDaySlot={false}
          height={isMobile ? "100%" : "auto"}
          expandRows
          eventContent={(eventInfo: EventContentArg) => (
            <div style={{ display: "flex", flexDirection: "column", overflow: "hidden", height: "100%" }}>
              <div
                style={{
                  ...eventStyle,
                  flex: "0 0 auto",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
                title={eventInfo.event.extendedProps.description}
              >
                <strong>{eventInfo.event.title}</strong>
              </div>
              {eventInfo.event.extendedProps.meetingType && (
                <div style={{ flex: "1 1 auto" }}>
                  {eventInfo.event.extendedProps.meetingType === MeetingType.Physical
                    ? t("calendar.physicalMeeting")
                    : t("calendar.virtualMeeting")}
                </div>
              )}
              <div style={{ flex: "1 1 auto" }}>{eventInfo.timeText}</div>
            </div>
          )}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          buttonText={{
            today: t("calendar.today"),
            month: t("calendar.month"),
            week: t("calendar.week"),
            day: t("calendar.day"),
          }}
          titleFormat={(arg) => {
            const start = arg.start;
            return `${monthNamesLong[start.month]} ${start.year}`;
          }}
          dayHeaderContent={(arg) => {
            const jsDate = arg.date as Date;
            return `${monthNamesShort[jsDate.getMonth()]} ${jsDate.getDate()}`;
          }}
        />
      </Box>
    </MaijaContainer>
  );
};

export default ApplicantCalendar;

import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Divider,
  List,
  ListItemText,
  Paper,
  Typography,
  LinearProgress,
  ListItemButton,
  Fade,
  Backdrop,
  ListItemIcon,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MaijaContainer from "../../components/MaijaContainer";
import SkillsComponent from "./SkillsComponent";
import {
  fetchDriversLicenses,
  fetchEducations,
  fetchLanguages,
  fetchSelfAssessment,
  fetchSkills,
  fetchSupportInfo,
  fetchWorkExperiences,
} from "./ProfilePageRepository";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { ProfilePageState, setProfilePageData } from "../../reducers/profilePageSlice";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { useTranslation } from "react-i18next";
import LanguagesComponent from "./LanguagesComponent";
import WorkExperiencesComponent from "./WorkExperienceComponent";
import EducationsComponent from "./EducationComponent";
import SupportInfoComponent from "./SupportInfoComponent";
import DriversLicenseComponent from "./DriversLicenseComponent";
import {
  Timeline,
  Work as WorkIcon,
  School as SchoolIcon,
  Info as InfoIcon,
  Build as BuildIcon,
  Translate as TranslateIcon,
  QuestionAnswer as QuestionAnswerIcon,
  Description as DescriptionIcon,
  ArrowBack as ArrowBackIcon,
  CheckCircle as CheckCircleIcon,
} from "@mui/icons-material";
import { RootState } from "../../store";
import SelfAssessmentComponent from "./SelfAssessmentComponent";
import {
  calculateCompletionPercentage,
  educationsCompletion,
  introductionCompletion,
  languagesCompletion,
  profileDataIsPrestineLoading,
  selfAssessmentCompletion,
  skillsCompletion,
  supportInfoCompletion,
  workExperiencesCompletion,
  driversLicenseCompletion,
} from "./profileCompletionCalculations";
import { green } from "@mui/material/colors";
import IntroductionComponent from "./IntroductionComponent";
import CompletedProfileModal from "./CompletedProfileModal";
import MaijaLoadingPage from "../../components/MaijaLoadingPage";
import MaijaExperienceTimeline from "../../components/MaijaExperienceTimeline";
import PlatsbankenJobsCountPaper from "./PlatsbankenJobsCountPaper";
import ProfileCard from "./ProfileCard";

const componentsMap: { [key: string]: React.FC } = {
  "Work experience": WorkExperiencesComponent,
  Education: EducationsComponent,
  Skills: SkillsComponent,
  "Drivers license": DriversLicenseComponent,
  Language: LanguagesComponent,
  "Work situation": SupportInfoComponent,
  "Self assessment": SelfAssessmentComponent,
  Introduction: IntroductionComponent,
};

const ProfilePage: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [selectedComponent, setSelectedComponent] = useState<keyof typeof componentsMap | null>(null);
  const SelectedComponent = selectedComponent ? componentsMap[selectedComponent] : null;

  const workExperiences = useAppSelector((state: RootState) => state.profilePage.workExperiences);
  const educations = useAppSelector((state: RootState) => state.profilePage.educations);

  const loading = useFetchProfileData();
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const [openProfileDoneModal, setOpenProfileDoneModal] = useState(false);
  const pageState = useAppSelector((state: RootState) => state.profilePage);
  const completionPercentageRef = useRef(completionPercentage);

  const tabs = [
    {
      key: "Work experience",
      icon: <WorkIcon />,
      translatedKey: t("profilePage.components.Work experience"),
    },
    {
      key: "Education",
      icon: <SchoolIcon />,
      translatedKey: t("profilePage.components.Education"),
    },
    {
      key: "Skills",
      icon: <BuildIcon />,
      translatedKey: t("profilePage.components.Skills"),
    },
    {
      key: "Drivers license",
      icon: <DirectionsCarIcon />,
      translatedKey: t("profilePage.components.Drivers license"),
    },
    {
      key: "Language",
      icon: <TranslateIcon />,
      translatedKey: t("profilePage.components.Language"),
    },
    {
      key: "Work situation",
      icon: <InfoIcon />,
      translatedKey: t("profilePage.components.Work situation"),
    },
    {
      key: "Self assessment",
      icon: <QuestionAnswerIcon />,
      translatedKey: t("profilePage.components.Self assessment"),
    },
    {
      key: "Introduction",
      icon: <DescriptionIcon />,
      translatedKey: t("profilePage.components.Introduction"),
    },
  ];

  useEffect(() => {
    if (!loading) {
      const newCompletionPercentage = calculateCompletionPercentage(pageState);
      const oldCompletionPercentage = completionPercentageRef.current;

      setCompletionPercentage(newCompletionPercentage);
      completionPercentageRef.current = newCompletionPercentage;

      if (oldCompletionPercentage !== 100 && oldCompletionPercentage > 0 && newCompletionPercentage >= 100) {
        setOpenProfileDoneModal(true);
      }
    }
  }, [loading, pageState]);

  if (loading) {
    return <MaijaLoadingPage isFullscreen />;
  }

  const renderTabs = () => (
    <List sx={{ display: "flex", flexDirection: "column" }}>
      {tabs.map((tab) => {
        const isCompleted =
          (tab.key === "Work experience" &&
            pageState.workExperiences &&
            workExperiencesCompletion(pageState.workExperiences) >= 1) ||
          (tab.key === "Education" && pageState.educations && educationsCompletion(pageState.educations) >= 1) ||
          (tab.key === "Skills" && pageState.skills && skillsCompletion(pageState.skills) >= 1) ||
          (tab.key === "Drivers license" && pageState.driversLicenses && driversLicenseCompletion(pageState) >= 1) ||
          (tab.key === "Language" && pageState.languages && languagesCompletion(pageState.languages) >= 1) ||
          (tab.key === "Work situation" &&
            pageState.supportInfo &&
            supportInfoCompletion(pageState.supportInfo) >= 1) ||
          (tab.key === "Introduction" && pageState.supportInfo && introductionCompletion(pageState.supportInfo) >= 1) ||
          (tab.key === "Self assessment" &&
            pageState.selfAssessmentQuestions &&
            selfAssessmentCompletion(pageState.selfAssessmentQuestions) >= 1);

        return (
          <ListItemButton
            selected={selectedComponent === tab.key}
            sx={{ m: 1, borderRadius: 8 }}
            key={tab.key}
            onClick={() => setSelectedComponent(tab.key as keyof typeof componentsMap)}
            data-cy={`profile-page-${tab.key}`}
          >
            <ListItemIcon sx={{ minWidth: 40 }}>{tab.icon}</ListItemIcon>
            <ListItemText primary={tab.translatedKey} />
            {!loading && isCompleted && completionPercentage < 100 && (
              <Fade in={true} timeout={400}>
                <CheckCircleIcon style={{ fontSize: 30, color: green[500] }} />
              </Fade>
            )}
          </ListItemButton>
        );
      })}
    </List>
  );

  return (
    <MaijaContainer sx={{ pb: 10, pr: isMobile ? 5 : 0 }} maxWidth={false}>
      {completionPercentage < 100 && !loading && (
        <Box sx={{ mt: 10, mb: 5 }}>
          <LinearProgress variant="determinate" value={completionPercentage} color="primary" />
        </Box>
      )}
      {completionPercentage === 100 && <Box sx={{ width: "100%", mt: 10, mb: 5 }}></Box>}

      <ProfileCard />
      <PlatsbankenJobsCountPaper t={t} supportInfo={pageState.supportInfo} />

      {!isMobile ? (
        <Paper sx={{ borderRadius: 6, mb: 5, p: 7 }}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <Timeline sx={{ mr: 3, fontSize: 32, color: "rgba(0, 0, 0, 0.54)" }} />
            <Typography variant="h3">{t("profilePage.experienceTimeline.timeline")}</Typography>
          </Box>
          {workExperiences && educations && (
            <MaijaExperienceTimeline workExperiences={workExperiences} educations={educations} />
          )}
        </Paper>
      ) : (
        ""
      )}

      {isMobile ? (
        <Paper sx={{ borderRadius: 6, p: 2 }}>
          {!selectedComponent ? (
            renderTabs()
          ) : (
            <Box>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <IconButton onClick={() => setSelectedComponent(null)}>
                  <ArrowBackIcon />
                </IconButton>
                <Typography variant="h5" sx={{ ml: 2 }}>
                  {tabs.find((tab) => tab.key === selectedComponent)?.translatedKey}
                </Typography>
              </Box>
              {SelectedComponent && <SelectedComponent />}
            </Box>
          )}
        </Paper>
      ) : (
        <Paper sx={{ display: "flex", borderRadius: 6 }}>
          <Box sx={{ width: "230px" }}>{renderTabs()}</Box>
          <Divider orientation="vertical" flexItem />
          <Box sx={{ flex: 1, p: 4 }}>{SelectedComponent && <SelectedComponent />}</Box>
        </Paper>
      )}

      <CompletedProfileModal isOpen={openProfileDoneModal} handleClose={() => setOpenProfileDoneModal(false)} />
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={profileDataIsPrestineLoading(pageState)}>
        <MaijaLoadingPage isFullscreen={true} />
      </Backdrop>
    </MaijaContainer>
  );
};

const useFetchProfileData = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [workExperiences, educations, skills, languages, supportInfo, selfAssessmentQuestions, driversLicenses] =
          await Promise.all([
            fetchWorkExperiences(),
            fetchEducations(),
            fetchSkills(),
            fetchLanguages(),
            fetchSupportInfo(),
            fetchSelfAssessment(),
            fetchDriversLicenses(),
          ]);

        const profileData: ProfilePageState = {
          workExperiences,
          educations,
          skills,
          languages,
          supportInfo,
          selfAssessmentQuestions,
          driversLicenses,
        };

        dispatch(setProfilePageData(profileData));
      } catch (error) {
        dispatch(
          showSnackbar({
            message: t("profilePage.supportInfo.loadError"),
            severity: "error",
          }),
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, t]);

  return loading;
};

export default ProfilePage;

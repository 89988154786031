import React, { useContext, useEffect, useState } from "react";
import {
  AppBar,
  Avatar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useAppSelector } from "../hooks";
import MaijaRoute, { CHANGE_PASSWORD_ROUTE, PROFILE_ROUTE } from "../Routes";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { useLogout } from "../utils/useLogout";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { Language } from "@mui/icons-material";
import Flag from "react-world-flags";
import { RootState } from "../store";
import { AuthContext } from "../providers/AuthProvider";
import MaijaLoadingPage from "./MaijaLoadingPage";
import { appBarTextInactiveDark, appBarTextInactiveLight } from "./MaijaColors";
import { alpha } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";

const languages = [
  { code: "sv", name: "Svenska", flag: "SE" },
  { code: "en", name: "English", flag: "GB" },
  { code: "ar", name: "العربية", flag: "SA" },
  { code: "da", name: "Dansk", flag: "DK" },
  { code: "de", name: "Deutsch", flag: "DE" },
  { code: "fi", name: "Suomi", flag: "FI" },
  { code: "fr", name: "Français", flag: "FR" },
  { code: "ku", name: "Kurdî", flag: "IQ" },
  { code: "pl", name: "Polski", flag: "PL" },
  { code: "so", name: "Soomaali", flag: "SO" },
  { code: "ti", name: "ትግርኛ", flag: "ER" },
  { code: "vi", name: "Tiếng Việt", flag: "VN" },
  { code: "zh", name: "中文", flag: "CN" },
  { code: "no", name: "Norsk", flag: "NO" },
  { code: "fa", name: "فارسی", flag: "IR" },
  { code: "fil", name: "Filipino", flag: "PH" },
];

export const DRAWER_WIDTH = 210;

const getContrastColor = (hexColor: string) => {
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance > 0.5 ? "#000000" : "#FFFFFF";
};

const getContrastColorInactive = (hexColor: string) => {
  return hexColor === "#000000" ? appBarTextInactiveDark : appBarTextInactiveLight;
};

interface MaijaAppBarProps {
  routes: MaijaRoute[];
}

const ApplicantAppBar: React.FC<MaijaAppBarProps> = ({ routes }) => {
  const logout = useLogout();
  const [isLoading, setIsLoading] = useState(false);
  const logo = useAppSelector((state: RootState) => state.theme.logo);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const tertiaryColor = useAppSelector((state: RootState) => state.theme.tertiaryColor);
  const tertiaryContrastTextColor = getContrastColor(tertiaryColor);
  const tertiaryContrastTextInactiveColor = getContrastColorInactive(tertiaryContrastTextColor);
  const settingsLoaded = useAppSelector((state: RootState) => state.theme.settingsLoaded);

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [anchorElLang, setAnchorElLang] = useState<null | HTMLElement>(null);
  const [mobileOpen, setMobileOpen] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenLangMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElLang(event.currentTarget);
  };

  const handleCloseLangMenu = () => {
    setAnchorElLang(null);
  };

  const handleLogout = async () => {
    setIsLoading(true);
    await logout();
    setIsLoading(false);
  };

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
    Cookies.set("language", lng, { expires: 365 });
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || Cookies.get("language");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  const settings = [
    { id: "change_password", name: t("settings.change_password") },
    { id: "logout", name: t("settings.logout") },
  ];

  if (isLoading || !settingsLoaded) {
    return <MaijaLoadingPage isFullscreen={true} />;
  }

  const drawerContent = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        backgroundColor: tertiaryColor,
        color: tertiaryContrastTextColor,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: theme.spacing(2),
          bgcolor: tertiaryColor,
          color: tertiaryContrastTextColor,
          mt: 4,
        }}
      >
        <Typography
          variant="h5"
          noWrap
          component="div"
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            userSelect: "none",
            marginBottom: theme.spacing(2),
          }}
          onClick={() => {
            if (isMobile) setMobileOpen(false);
            navigate(PROFILE_ROUTE.path);
          }}
        >
          {logo ? (
            <img src={logo} alt="Logo" style={{ height: "40px", width: "auto" }} />
          ) : (
            <img
              src="https://maija.blob.core.windows.net/company/MAIJA_V3.svg"
              alt="Logo"
              style={{ height: "40px", width: "auto" }}
            />
          )}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: theme.spacing(2),
            marginBottom: theme.spacing(2),
            mt: 4,
          }}
        >
          <Tooltip title="Select language">
            <IconButton onClick={handleOpenLangMenu} sx={{ p: 0 }}>
              <Avatar sx={{ bgcolor: "#c7c7c7" }}>
                <Language />
              </Avatar>
            </IconButton>
          </Tooltip>
          <Tooltip title="Open settings" data-cy="app-bar-route.settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar
                alt={user?.name}
                src={user?.profileIconUrl || undefined}
                sx={{ bgcolor: theme.palette.secondary.light }}
              />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <List>
        {routes.map((route) => {
          const isSelected = Boolean(matchPath({ path: route.path, end: true }, location.pathname));
          return (
            <ListItem
              button
              key={route.path}
              selected={isSelected}
              onClick={() => {
                if (isMobile) setMobileOpen(false);
                navigate(route.path);
              }}
              sx={{
                color: isSelected ? tertiaryContrastTextColor : tertiaryContrastTextInactiveColor,
                backgroundColor: isSelected ? alpha(tertiaryContrastTextInactiveColor, 0.1) : "transparent",
                "&:hover": {
                  color: tertiaryContrastTextColor,
                  backgroundColor: alpha(tertiaryContrastTextInactiveColor, 0.1),
                },
                transition: "color 0.3s, background-color 0.3s",
              }}
            >
              <ListItemIcon sx={{ color: "inherit", my: "3vh" }}>
                {route.icon && React.cloneElement(route.icon, { style: { fontSize: "3rem" } })}
              </ListItemIcon>
              <ListItemText primary={t(route.nameKey)} />
            </ListItem>
          );
        })}
      </List>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-lang"
        anchorEl={anchorElLang}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElLang)}
        onClose={handleCloseLangMenu}
      >
        {languages.map((lang) => (
          <MenuItem
            key={lang.code}
            onClick={() => {
              changeLanguage(lang.code);
              handleCloseLangMenu();
            }}
          >
            <ListItemIcon>
              <Flag code={lang.flag} style={{ width: 24, height: 24 }} alt={lang.name} />
            </ListItemIcon>
            <ListItemText primary={lang.name} />
          </MenuItem>
        ))}
      </Menu>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings.map((setting) => (
          <MenuItem
            key={setting.id}
            onClick={() => {
              if (setting.id === "logout") {
                handleLogout();
              } else if (setting.id === "change_password") {
                navigate(CHANGE_PASSWORD_ROUTE.path);
              }
              handleCloseUserMenu();
            }}
            data-cy={`app-bar-route.settings-${setting.id}`}
          >
            <Typography textAlign="center">{setting.name}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );

  return (
    <>
      {isMobile ? (
        <>
          <AppBar
            position="fixed"
            sx={{
              backgroundColor: tertiaryColor,
              color: tertiaryContrastTextColor,
            }}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                variant="h6"
                sx={{ flexGrow: 1, cursor: "pointer" }}
                onClick={() => navigate(PROFILE_ROUTE.path)}
              >
                {logo ? (
                  <img src={logo} alt="Logo" style={{ height: "30px", width: "auto" }} />
                ) : (
                  <img
                    src="https://maija.blob.core.windows.net/company/MAIJA_V3.svg"
                    alt="Logo"
                    style={{ height: "30px", width: "auto" }}
                  />
                )}
              </Typography>
            </Toolbar>
          </AppBar>
          <Toolbar /> {/* To push content below AppBar */}
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: DRAWER_WIDTH,
              },
            }}
          >
            {drawerContent}
          </Drawer>
        </>
      ) : (
        <Drawer
          variant="permanent"
          anchor="left"
          sx={{
            width: DRAWER_WIDTH,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: DRAWER_WIDTH,
              boxSizing: "border-box",
              backgroundColor: tertiaryColor,
              color: tertiaryContrastTextColor,
            },
          }}
        >
          {drawerContent}
        </Drawer>
      )}
    </>
  );
};

export default ApplicantAppBar;

import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  LOGIN_ROUTE,
  PROFILE_ROUTE,
  APPLICATION_ROUTE,
  APPLICATION_LIST_ROUTE,
  APPLICANT_CALENDAR_ROUTE,
} from "../Routes";
import ApplicantAppBar from "./ApplicantAppBar";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../providers/AuthProvider";
import { getSettings } from "../pages/login/LoginRepository";
import {
  setPrimaryColor as setReduxPrimaryColor,
  setSecondaryColor as setReduxSecondaryColor,
  setTertiaryColor as setReduxTertiaryColor,
  setLogo as setReduxLogo,
  setLoginImage as setReduxLoginImage,
  setSettingsLoaded as setReduxSettingsLoaded,
} from "../reducers/themeSlice";
import MaijaLoadingPage from "./MaijaLoadingPage";
import { useAppDispatch, useAppSelector } from "../hooks";
import { RootState } from "../store";

type ProtectedRouteProps = {
  children: React.ReactNode;
  requireApplicationDetails?: boolean;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, requireApplicationDetails = false }) => {
  const { user, loading: authLoading } = useContext(AuthContext);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams<{ id: string }>();

  const [settingsLoading, setSettingsLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(true);

  const applicationDetails = useAppSelector((state: RootState) =>
    id ? state.applicationDetailsPage.applicationsMap[id] : undefined,
  );

  useEffect(() => {
    if (!authLoading && !user) {
      navigate(LOGIN_ROUTE.path, { replace: true, state: { from: location } });
    }
  }, [authLoading, user, navigate, location]);

  useEffect(() => {
    if (user && settingsLoading) {
      const fetchAndSetApplicantSettings = async () => {
        try {
          const settings = await getSettings(user.companyId);
          if (settings) {
            dispatch(setReduxPrimaryColor(settings.primary_color || "#FFFFFF"));
            dispatch(setReduxSecondaryColor(settings.secondary_color || "#FFFFFF"));
            dispatch(setReduxTertiaryColor(settings.tertiary_color || "#FFFFFF"));
            dispatch(setReduxLogo(settings.logo ?? null));
            dispatch(setReduxLoginImage(settings.login_image ?? null));
          }
        } catch (error) {
          console.error("Failed to load company settings:", error);
        } finally {
          setSettingsLoading(false);
          dispatch(setReduxSettingsLoaded(true));
        }
      };
      fetchAndSetApplicantSettings();
    } else {
      setSettingsLoading(false);
    }
  }, [user, settingsLoading, dispatch]);

  useEffect(() => {
    if (user && requireApplicationDetails) {
      if (id && !applicationDetails) {
        navigate(APPLICATION_LIST_ROUTE.path);
      }
    }
    setDataLoading(false);
  }, [user, requireApplicationDetails, id, applicationDetails, dispatch, navigate]);

  if (authLoading || settingsLoading || dataLoading) {
    return <MaijaLoadingPage isFullscreen />;
  }

  if (!user) {
    return null;
  }

  const toolbarRoutes = [PROFILE_ROUTE, APPLICATION_ROUTE, APPLICATION_LIST_ROUTE, APPLICANT_CALENDAR_ROUTE];

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <ApplicantAppBar routes={toolbarRoutes} />
      <div style={{ flexGrow: 1, overflow: "auto" }}>{children}</div>
    </div>
  );
};

export default ProtectedRoute;

import { Box, Skeleton, Paper, IconButton, styled, Typography, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { GetApp, Email, Close, Edit } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import MaijaModal from "../../../components/MaijaModal";
import { getCoverLetterPreview, downloadApplication, emailApplication } from "../../application/ApplicationRepository";
import { showSnackbar } from "../../../reducers/snackbarSlice";
import { useAppDispatch } from "../../../hooks";
import { useTranslation } from "react-i18next";
import { downloadBase64File } from "../../../utils/FileDownloadUtil";

interface CoverLetterPreviewModalProps {
  isOpen: boolean;
  coverLetterId: string;
  applicationId: string;
  hasApplied: boolean;
  handleClose: () => void;
  onEditCoverLetter: () => void;
  refreshKey: number;
}

const A4Paper = styled(Paper)(({ theme }) => ({
  width: "100%",
  maxWidth: "600px",
  display: "flex",
  flexDirection: "column",
  boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
  borderRadius: 8,
  position: "relative",
  marginBottom: theme.spacing(2),
}));

const CoverLetterPreviewModal: React.FC<CoverLetterPreviewModalProps> = ({
  isOpen,
  coverLetterId,
  applicationId,
  hasApplied,
  handleClose,
  onEditCoverLetter,
  refreshKey,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [thumbnails, setThumbnails] = useState<string[]>([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      getCoverLetterPreview(coverLetterId)
        .then((response) => setThumbnails(response.thumbnails))
        .catch(() =>
          dispatch(
            showSnackbar({ message: t("applicationDetails.coverLetterPreviewModal.errorLoading"), severity: "error" }),
          ),
        )
        .finally(() => setIsLoading(false));
    }
  }, [isOpen, refreshKey, coverLetterId, dispatch, t]);

  const handleDownload = () => {
    downloadApplication(applicationId)
      .then((data) => data && downloadBase64File(data.coverLetter, `Cover_Letter_${applicationId}`))
      .catch(() =>
        dispatch(showSnackbar({ message: t("applicationDetails.page.downloadingError"), severity: "error" })),
      );
  };

  const handleEmail = () => {
    emailApplication(applicationId).catch(() =>
      dispatch(showSnackbar({ message: t("applicationDetails.page.emailingError"), severity: "error" })),
    );
  };

  return (
    <MaijaModal isOpen={isOpen} handleClose={handleClose} width="90%" maxWidth="600px" maxHeight="95vh">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          p: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            mb: 2,
          }}
        >
          <Typography variant="h6">{t("applicationDetails.page.coverLetter")}</Typography>
          <Box>
            {!hasApplied && (
              <Tooltip title={t("applicationDetails.page.editTooltip")}>
                <IconButton onClick={onEditCoverLetter}>
                  <Edit />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title={t("applicationDetails.page.downloadTooltip")}>
              <IconButton onClick={handleDownload}>
                <GetApp />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("applicationDetails.page.emailTooltip")}>
              <IconButton onClick={handleEmail}>
                <Email />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        {isLoading ? (
          <Skeleton
            variant="rounded"
            animation="wave"
            width="100%"
            height={isMobile ? "60vh" : "80vh"}
            sx={{ maxWidth: 600 }}
          />
        ) : (
          thumbnails.map((thumbnail, index) => (
            <A4Paper key={index}>
              <Box
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                  display: "flex",
                  gap: 1,
                  zIndex: 10,
                }}
              >
                <Tooltip title={t("applicationDetails.page.closeTooltip")}>
                  <IconButton
                    onClick={handleClose}
                    sx={{
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                      "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 1)",
                      },
                      padding: 0.5,
                    }}
                  >
                    <Close />
                  </IconButton>
                </Tooltip>
              </Box>

              <img
                src={`data:image/jpeg;base64,${thumbnail}`}
                alt={`Cover Letter Page ${index + 1}`}
                style={{ width: "100%", height: "auto", borderRadius: "8px" }}
              />
            </A4Paper>
          ))
        )}
      </Box>
    </MaijaModal>
  );
};

export default CoverLetterPreviewModal;

import React, { useState } from "react";
import { Paper, styled, Tooltip, Typography, Skeleton, useTheme } from "@mui/material";
import { Box, SxProps, Theme, useMediaQuery } from "@mui/system";
import { useTranslation } from "react-i18next";

interface DocumentPreviewProps {
  content?: string;
  tooltipText: string;
  title?: string;
  sx?: SxProps<Theme>;
  onClick?: () => void;
}

const A4Paper = styled(Paper)(() => ({
  width: "100%",
  position: "relative",
  paddingTop: "141.4%",
  overflow: "hidden",
  boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
  transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
  "&:hover": {
    transform: "translateY(-3px)",
    boxShadow: "0 4px 16px rgba(0,0,0,0.2)",
  },
}));

const DocumentPreview: React.FC<DocumentPreviewProps> = ({ content, title, tooltipText, onClick }) => {
  const { t } = useTranslation();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleTooltipClose = () => setTooltipOpen(false);
  const handleTooltipOpen = () => setTooltipOpen(true);

  const isLoading = !content;
  const imgSrc = content?.startsWith("data:image") ? content : `data:image/jpeg;base64,${content}`;

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {title && (
        <Typography variant="h6" sx={{ mb: 2, alignSelf: "flex-start" }}>
          {title}
        </Typography>
      )}
      <Tooltip
        title={tooltipText}
        placement="top"
        arrow
        open={tooltipOpen}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        onMouseEnter={handleTooltipOpen}
        onMouseLeave={handleTooltipClose}
        onFocus={handleTooltipOpen}
        onBlur={handleTooltipClose}
      >
        <div>
          {isLoading ? (
            <Skeleton variant="rounded" animation="wave" width="100%" sx={{ paddingTop: "141.4%" }} />
          ) : (
            <A4Paper
              elevation={10}
              onClick={() => {
                handleTooltipClose();
                onClick?.();
              }}
            >
              <img
                src={imgSrc}
                alt={t("applicationDetails.documentPreview.documentThumbnail")}
                style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", objectFit: "cover" }}
              />
            </A4Paper>
          )}
        </div>
      </Tooltip>
    </Box>
  );
};

export default DocumentPreview;

import React, { useMemo, useState, useCallback } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useAppDispatch } from "../../hooks";
import { showSnackbar } from "../../reducers/snackbarSlice";
import {
  submitDriversLicense,
  deleteAllExceptMissingDriversLicenses,
  deleteDriversLicense,
} from "./ProfilePageRepository";
import {
  addDriversLicense,
  deleteDriversLicense as removeDriversLicense,
  deleteAllDriversLicenses as removeAllDriversLicenses,
} from "../../reducers/profilePageSlice";
import licenseData from "../../data/driversLicenses.json";
import { useTranslation } from "react-i18next";

interface LicenseOption {
  license: string;
  description: string;
}

const groupIcons: { [key: string]: JSX.Element } = {
  motorcycle: <TwoWheelerIcon />,
  car: <DirectionsCarIcon />,
  truck: <LocalShippingIcon />,
  bus: <DirectionsBusIcon />,
  other: <HelpOutlineIcon />,
};

const groupMapping: { [key: string]: string } = {
  A: "motorcycle",
  B: "car",
  C: "truck",
  D: "bus",
};

const LicenseItem: React.FC<{
  license: LicenseOption;
  isChecked: boolean;
  isLoading: boolean;
  onToggle: () => void;
  disabled: boolean;
}> = React.memo(({ license, isChecked, isLoading, onToggle, disabled }) => (
  <Grid item xs={12} sm={6} md={4} key={`${license.license}`}>
    <FormControlLabel
      control={
        <Checkbox
          checked={isChecked}
          onChange={onToggle}
          color="primary"
          disabled={isLoading || disabled}
          inputProps={{
            "aria-label": `${license.license} checkbox`,
          }}
        />
      }
      label={
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box>
            <Typography variant="body1">{license.license}</Typography>
            <Typography variant="caption" color="textSecondary">
              {license.description}
            </Typography>
          </Box>
          {isLoading && <CircularProgress size={20} sx={{ ml: 1 }} />}
        </Box>
      }
    />
  </Grid>
));

LicenseItem.displayName = "LicenseItem";

const DriversLicenseComponent: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const profilePageState = useSelector((state: RootState) => state.profilePage);
  const { driversLicenses } = profilePageState;

  const [loadingLicenses, setLoadingLicenses] = useState<Set<string>>(new Set());

  const licenseOptions: LicenseOption[] = useMemo(() => {
    return licenseData.licenses.map((item) => ({
      license: item.license,
      description: item.description,
    }));
  }, []);

  const { groupedLicenses, missingLicense } = useMemo((): {
    groupedLicenses: { [key: string]: LicenseOption[] };
    missingLicense: LicenseOption | null;
  } => {
    const grouped: { [key: string]: LicenseOption[] } = {};
    let missing: LicenseOption | null = null;

    licenseOptions.forEach((license) => {
      if (license.license.trim().toLowerCase() === "missing") {
        missing = license;
      } else {
        const firstChar = license.license.charAt(0).toUpperCase();
        const groupName = groupMapping[firstChar] || "other";

        if (!grouped[groupName]) {
          grouped[groupName] = [];
        }

        grouped[groupName].push(license);
      }
    });

    return { groupedLicenses: grouped, missingLicense: missing };
  }, [licenseOptions]);

  const sortedGroupNames = useMemo(() => {
    const orderedKeys = Object.keys(groupMapping);

    const orderedGroupNames = orderedKeys
      .map((key) => groupMapping[key])
      .filter((groupName) => groupedLicenses[groupName]);

    const remainingGroupNames = Object.keys(groupedLicenses).filter(
      (groupName) => !orderedGroupNames.includes(groupName),
    );

    return [...orderedGroupNames, ...remainingGroupNames];
  }, [groupedLicenses]);

  const currentLicensesSet = useMemo(() => {
    return new Set(driversLicenses.map((license) => license.name.toLowerCase()));
  }, [driversLicenses]);

  const selectedCounts = useMemo(() => {
    const counts: { [key: string]: number } = {};
    Object.keys(groupedLicenses).forEach((groupName) => {
      const selected = groupedLicenses[groupName].filter((license) =>
        currentLicensesSet.has(license.license.toLowerCase()),
      ).length;
      counts[groupName] = selected;
    });
    return counts;
  }, [groupedLicenses, currentLicensesSet]);

  const isMissingSelected = missingLicense ? currentLicensesSet.has(missingLicense.license.toLowerCase()) : false;

  const handleToggleLicense = useCallback(
    async (licenseName: string) => {
      const normalizedLicense = licenseName.toLowerCase();
      const isCurrentlyChecked = currentLicensesSet.has(normalizedLicense);

      if (loadingLicenses.has(normalizedLicense)) {
        return;
      }

      setLoadingLicenses((prev) => new Set(prev).add(normalizedLicense));

      try {
        if (isCurrentlyChecked) {
          const licenseToRemove = driversLicenses.find((lic) => lic.name.toLowerCase() === normalizedLicense);

          if (!licenseToRemove) {
            throw new Error(`License '${licenseName}' not found.`);
          }

          await deleteDriversLicense(licenseToRemove.id);

          dispatch(removeDriversLicense(licenseToRemove.id));

          dispatch(
            showSnackbar({
              message: t("profilePage.license.deleteSuccess"),
              severity: "success",
            }),
          );
        } else {
          if (isMissingSelected) {
            setLoadingLicenses((prev) => new Set(prev).add("bulk-delete-all"));
            try {
              await deleteAllExceptMissingDriversLicenses();
              dispatch(removeAllDriversLicenses());
              dispatch(
                showSnackbar({
                  message: t("profilePage.license.deleteSuccessBulk"),
                  severity: "success",
                }),
              );
            } catch (error) {
              console.error("Error deleting all except 'Missing' driver's licenses:", error);
              dispatch(
                showSnackbar({
                  message: t("profilePage.license.deleteErrorBulk"),
                  severity: "error",
                }),
              );
              throw error;
            } finally {
              setLoadingLicenses((prev) => {
                const newSet = new Set(prev);
                newSet.delete("bulk-delete-all");
                return newSet;
              });
            }
          }

          const addedLicense = await submitDriversLicense({ name: licenseName });
          dispatch(addDriversLicense(addedLicense));
          dispatch(
            showSnackbar({
              message: t("profilePage.license.addSuccess"),
              severity: "success",
            }),
          );
        }
      } catch (error) {
        console.error("Error toggling driver's license:", error);
        dispatch(
          showSnackbar({
            message: isCurrentlyChecked ? t("profilePage.license.deleteError") : t("profilePage.license.addError"),
            severity: "error",
          }),
        );
      } finally {
        setLoadingLicenses((prev) => {
          const newSet = new Set(prev);
          newSet.delete(normalizedLicense);
          return newSet;
        });
      }
    },
    [dispatch, currentLicensesSet, driversLicenses, isMissingSelected, t, loadingLicenses],
  );

  const handleToggleMissing = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked;

      if (isChecked) {
        if (loadingLicenses.has("bulk-delete-all")) {
          return;
        }
        setLoadingLicenses((prev) => new Set(prev).add("bulk-delete-all"));
        try {
          await deleteAllExceptMissingDriversLicenses();
          dispatch(removeAllDriversLicenses());
          const addedMissingLicense = await submitDriversLicense({ name: "Missing" });
          dispatch(addDriversLicense(addedMissingLicense));
          dispatch(
            showSnackbar({
              message: t("profilePage.license.addSuccess"),
              severity: "success",
            }),
          );
        } catch (error) {
          console.error("Error handling 'Missing' selection:", error);
          dispatch(
            showSnackbar({
              message: t("profilePage.license.deleteErrorBulk"),
              severity: "error",
            }),
          );
        } finally {
          setLoadingLicenses((prev) => {
            const newSet = new Set(prev);
            newSet.delete("bulk-delete-all");
            return newSet;
          });
        }
      } else {
        const missingLicenseItem = driversLicenses.find((lic) => lic.name.toLowerCase() === "missing");

        if (missingLicenseItem) {
          if (loadingLicenses.has("missing")) {
            return;
          }

          setLoadingLicenses((prev) => new Set(prev).add("missing"));
          try {
            await deleteDriversLicense(missingLicenseItem.id);
            dispatch(removeDriversLicense(missingLicenseItem.id));
            dispatch(
              showSnackbar({
                message: t("profilePage.license.deleteSuccess"),
                severity: "success",
              }),
            );
          } catch (error) {
            console.error("Error deleting 'Missing' license:", error);
            dispatch(
              showSnackbar({
                message: t("profilePage.license.deleteError"),
                severity: "error",
              }),
            );
          } finally {
            setLoadingLicenses((prev) => {
              const newSet = new Set(prev);
              newSet.delete("missing");
              return newSet;
            });
          }
        }
      }
    },
    [dispatch, driversLicenses, t, loadingLicenses],
  );

  return (
    <Box sx={{ p: 2, height: "100%", overflowY: "auto" }}>
      <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
        {t("profilePage.license.title")}
      </Typography>

      {sortedGroupNames.map((groupName) => (
        <Accordion key={groupName}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${groupName}-content`}
            id={`${groupName}-header`}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {groupIcons[groupName] || <HelpOutlineIcon />}
                <Typography variant="subtitle1" sx={{ ml: 1, fontWeight: "bold" }}>
                  {t(`profilePage.license.groups.${groupName}`) || groupName}
                </Typography>
              </Box>
              <Typography variant="body2" color="textSecondary">
                {selectedCounts[groupName]}/{groupedLicenses[groupName].length}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              {groupedLicenses[groupName].map((license) => {
                const isChecked = currentLicensesSet.has(license.license.toLowerCase());
                const isLoading = loadingLicenses.has(license.license.toLowerCase());

                return (
                  <LicenseItem
                    key={`${license.license}`}
                    license={license}
                    isChecked={isChecked}
                    isLoading={isLoading}
                    onToggle={() => handleToggleLicense(license.license)}
                    disabled={isMissingSelected}
                  />
                );
              })}
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}

      {missingLicense && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mt: 4,
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={isMissingSelected}
                onChange={handleToggleMissing}
                color="primary"
                inputProps={{
                  "aria-label": "I have no driver's license checkbox",
                }}
                disabled={loadingLicenses.has("missing") || loadingLicenses.has("bulk-delete-all")}
              />
            }
            label={t("profilePage.license.missing.description") || "I do not possess a driver's license"}
          />
          {(loadingLicenses.has("missing") || loadingLicenses.has("bulk-delete-all")) && (
            <CircularProgress size={20} sx={{ ml: 1 }} />
          )}
        </Box>
      )}
    </Box>
  );
};

export default DriversLicenseComponent;

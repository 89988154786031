import React, { useEffect, useState, useMemo } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Autocomplete,
  CircularProgress,
  ListItem,
  ListItemText,
} from "@mui/material";
import { addSkill, updateSkill } from "../../reducers/profilePageSlice";
import { submitSkill, updateSkill as apiUpdateSkill } from "./ProfilePageRepository";
import { useAppDispatch } from "../../hooks";
import { Skill } from "../../types/Skill";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { useTranslation } from "react-i18next";
import debounce from "lodash.debounce";

interface SkillModalProps {
  initialSkill: Skill | null;
  open: boolean;
  onClose: () => void;
  existingSkills?: Skill[];
}

interface SkillOption {
  id: string;
  skill_name: string;
  isPlaceholder?: boolean;
}

const SkillModal: React.FC<SkillModalProps> = ({ open, onClose, initialSkill, existingSkills = [] }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [skillName, setSkillName] = useState(initialSkill?.name ?? "");
  const [isEditMode, setIsEditMode] = useState(initialSkill !== null);
  const [skillOptions, setSkillOptions] = useState<SkillOption[]>([]);
  const [loading, setLoading] = useState(false);
  const [autocompleteInput, setAutocompleteInput] = useState("");
  const [debouncedInput, setDebouncedInput] = useState("");

  const updateDebouncedInput = useMemo(
    () =>
      debounce((value: string) => {
        setDebouncedInput(value);
      }, 300),
    [],
  );

  const isTyping = autocompleteInput !== debouncedInput;

  const handleAddSkill = async (newSkill: { name: string }) => {
    try {
      const newSkillData = await submitSkill(newSkill);
      dispatch(addSkill(newSkillData));
      dispatch(showSnackbar({ message: t("profilePage.skill.addSuccess"), severity: "success" }));
    } catch (error) {
      dispatch(showSnackbar({ message: t("profilePage.skill.addError"), severity: "error" }));
    }
  };

  const handleUpdateSkill = async (updatedSkill: { name: string; id: string }) => {
    try {
      const updatedSkillData = await apiUpdateSkill(updatedSkill);
      dispatch(updateSkill(updatedSkillData));
      dispatch(showSnackbar({ message: t("profilePage.skill.updateSuccess"), severity: "success" }));
    } catch (error) {
      dispatch(showSnackbar({ message: t("profilePage.skill.updateError"), severity: "error" }));
    }
  };

  const loadSkills = async () => {
    setLoading(true);
    try {
      const skillsModule = await import("../../data/skills.json");
      const loadedSkills = skillsModule.data.concepts as SkillOption[];
      setSkillOptions(loadedSkills);
    } catch (error) {
      dispatch(showSnackbar({ message: t("profilePage.skill.loadError"), severity: "error" }));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      loadSkills();
      if (initialSkill) {
        setSkillName(initialSkill.name);
      }
      setIsEditMode(initialSkill !== null);
    } else {
      setSkillName("");
      setAutocompleteInput("");
      setDebouncedInput("");
      setIsEditMode(false);
    }
    return () => updateDebouncedInput.cancel();
  }, [open, initialSkill, updateDebouncedInput]);

  useEffect(() => {
    updateDebouncedInput(autocompleteInput);
  }, [autocompleteInput, updateDebouncedInput]);

  const handleSave = () => {
    const isDuplicate = existingSkills.some((skill) => skill.name.toLowerCase() === skillName.toLowerCase());

    if (isDuplicate) {
      dispatch(
        showSnackbar({
          message: t("profilePage.skill.duplicateError") || "This skill has already been added.",
          severity: "error",
        }),
      );
      return;
    }

    if (initialSkill) {
      handleUpdateSkill({ name: skillName, id: initialSkill.id });
    } else {
      handleAddSkill({ name: skillName });
    }
    handleClose();
  };

  const handleClose = () => {
    setSkillName("");
    setAutocompleteInput("");
    setDebouncedInput("");
    onClose();
  };

  const availableSkillOptions = useMemo(() => {
    const addedSkills = new Set(existingSkills.map((skill) => skill.name.toLowerCase()));
    return skillOptions.filter((option) => !addedSkills.has(option.skill_name.toLowerCase()));
  }, [skillOptions, existingSkills]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>{isEditMode ? t("profilePage.skill.editTitle") : t("profilePage.skill.addTitle")}</DialogTitle>

      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : (
          <Autocomplete
            options={availableSkillOptions}
            getOptionLabel={(option) => option.skill_name}
            value={availableSkillOptions.find((item) => item.skill_name === skillName) || null}
            onChange={(event, newValue) => {
              if (newValue && !newValue.isPlaceholder) {
                setSkillName(newValue.skill_name);
              }
            }}
            onInputChange={(event, newInputValue) => setAutocompleteInput(newInputValue)}
            filterOptions={() => {
              if (!debouncedInput.trim()) return [];

              const filtered = availableSkillOptions.filter((option) =>
                option.skill_name.toLowerCase().includes(debouncedInput.toLowerCase()),
              );
              const sliced = filtered.slice(0, 10).sort((a, b) => a.skill_name.localeCompare(b.skill_name));
              if (filtered.length > 10) {
                sliced.push({
                  id: "placeholder",
                  skill_name: t("profilePage.skill.moreResults", {
                    defaultValue: "Refine your search for more results...",
                  }),
                  isPlaceholder: true,
                });
              }

              return sliced;
            }}
            noOptionsText={
              isTyping
                ? t("profilePage.skill.searchingText", {
                    defaultValue: "Searching...",
                  })
                : !debouncedInput.trim()
                  ? t("profilePage.skill.noOptionsText", {
                      defaultValue: "Please start typing to show options",
                    })
                  : t("profilePage.skill.noMatchesText", {
                      defaultValue: "No matches found",
                    })
            }
            renderOption={(props, option) => {
              if (option.isPlaceholder) {
                return (
                  <ListItem
                    {...props}
                    key="placeholder"
                    sx={{
                      pointerEvents: "none",
                      opacity: 0.7,
                      fontStyle: "italic",
                    }}
                  >
                    <ListItemText primary={option.skill_name} />
                  </ListItem>
                );
              }
              return (
                <ListItem {...props} key={option.id}>
                  <ListItemText primary={option.skill_name} />
                </ListItem>
              );
            }}
            renderInput={(params) => (
              <TextField {...params} autoFocus label={t("profilePage.skill.nameLabel")} fullWidth margin="dense" />
            )}
            isOptionEqualToValue={(option, value) => option.skill_name === value.skill_name}
            ListboxProps={{
              style: {
                maxHeight: 200,
                overflow: "auto",
              },
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t("generic.cancel")}
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          variant="contained"
          disabled={!skillName.trim()}
          data-cy="save-button"
        >
          {isEditMode ? t("profilePage.skill.saveChangesButton") : t("profilePage.skill.addButton")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SkillModal;

import { Meeting } from "../../types/Meeting";
import { SendOutTime } from "../../types/SendOutTime";
import axiosInstance from "../../utils/AxiosInstance";

export async function fetchUserMeetings(): Promise<Meeting[]> {
  const response = await axiosInstance.get("/meetings");
  return response.data;
}

export async function fetchUserSendOutTimes(): Promise<SendOutTime[]> {
  const response = await axiosInstance.get("/sendout-times");
  return response.data;
}

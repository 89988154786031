import React, { useState } from "react";
import { Box, Typography, IconButton, MenuItem, SelectChangeEvent, Select, Checkbox } from "@mui/material";
import Edit from "@mui/icons-material/Edit";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import SupportInfoModal from "./SupportInfoModal";
import { UpdateApplicantSupportInfo } from "./ProfilePageRepository";
import { updateSupportInfo as apiUpdateSupportInfo } from "./ProfilePageRepository";
import { setSupportInfo } from "../../reducers/profilePageSlice";
import { useAppDispatch } from "../../hooks";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

export type AddressInfo = {
  streetName: string;
  zipCode: string;
  city: string;
};

export type WorkHoursInfo = {
  canWorkDaytime: boolean;
  canWorkEvening: boolean;
  canWorkNighttime: boolean;
};

export type SupportInfoFieldValueMap = {
  [SupportInfoFieldType.Address]: AddressInfo;
  [SupportInfoFieldType.PhoneNumber]: string;
  [SupportInfoFieldType.FirstPreferredJob]: string;
  [SupportInfoFieldType.SecondPreferredJob]: string;
  [SupportInfoFieldType.ThirdPreferredJob]: string;
  [SupportInfoFieldType.DesiredJobInFiveYears]: string;
  [SupportInfoFieldType.HasWorkLimitations]: boolean;
  [SupportInfoFieldType.CanTakeWorkImmediately]: boolean;
  [SupportInfoFieldType.WorkHours]: WorkHoursInfo;
  [SupportInfoFieldType.WorkTime]: number;
  [SupportInfoFieldType.UnemploymentOrSickLeaveDate]: string;
  [SupportInfoFieldType.EducationLevel]: string;
  [SupportInfoFieldType.Description]: string;
  [SupportInfoFieldType.JobSearchLocation]: string[];
  [SupportInfoFieldType.Gender]: string;
};

export enum SupportInfoFieldType {
  Address = "address",
  PhoneNumber = "phoneNumber",
  FirstPreferredJob = "firstPreferredJob",
  SecondPreferredJob = "secondPreferredJob",
  ThirdPreferredJob = "thirdPreferredJob",
  DesiredJobInFiveYears = "desiredJobInFiveYears",
  HasWorkLimitations = "hasWorkLimitations",
  CanTakeWorkImmediately = "canTakeWorkImmediately",
  WorkHours = "workHours",
  WorkTime = "workTime",
  UnemploymentOrSickLeaveDate = "unemploymentOrSickLeaveDate",
  EducationLevel = "educationLevel",
  Description = "description",
  JobSearchLocation = "jobSearchLocation",
  Gender = "gender",
}

interface SupportInfoFieldProps<T extends SupportInfoFieldType> {
  type: T;
  info: SupportInfoFieldValueMap[T];
  leadingSlot?: JSX.Element;
  getUpdatedSupportInfo: (value: SupportInfoFieldValueMap[T]) => UpdateApplicantSupportInfo | undefined;
  forceSingleRow?: boolean;
}

const SupportInfoField = <T extends SupportInfoFieldType>({
  type,
  info,
  leadingSlot,
  getUpdatedSupportInfo,
  forceSingleRow = false,
}: SupportInfoFieldProps<T>) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDialogOpen = () => setIsModalOpen(true);
  const handleDialogClose = () => setIsModalOpen(false);

  const displayInfo = (): string => {
    switch (type) {
      case SupportInfoFieldType.Address: {
        const address = info as AddressInfo;
        return `${address.streetName}, ${address.zipCode}, ${address.city}`;
      }
      case SupportInfoFieldType.WorkTime: {
        const value = info as number;
        return value === 100 ? t("profilePage.supportInfo.fullTimeLabel") : `${value}%`;
      }
      case SupportInfoFieldType.JobSearchLocation: {
        const locations = info as string[];
        return locations.join(", ");
      }
      case SupportInfoFieldType.Gender: {
        const gender = info as keyof typeof GenderTranslations;
        return t(GenderTranslations[gender]);
      }
      default:
        return info as string;
    }
  };

  const typeLabel = () => {
    switch (type) {
      case SupportInfoFieldType.JobSearchLocation:
        return t("profilePage.supportInfo.jobSearchLocationLabel");
      case SupportInfoFieldType.Gender:
        return t("profilePage.supportInfo.genderLabel");
      case SupportInfoFieldType.Address:
        return t("profilePage.supportInfo.addressLabel");
      case SupportInfoFieldType.PhoneNumber:
        return t("profilePage.supportInfo.phoneNumberLabel");
      case SupportInfoFieldType.FirstPreferredJob:
        return t("profilePage.supportInfo.firstPreferredJobLabel");
      case SupportInfoFieldType.SecondPreferredJob:
        return t("profilePage.supportInfo.secondPreferredJobLabel");
      case SupportInfoFieldType.ThirdPreferredJob:
        return t("profilePage.supportInfo.thirdPreferredJobLabel");
      case SupportInfoFieldType.DesiredJobInFiveYears:
        return t("profilePage.supportInfo.desiredJobInFiveYearsLabel");
      case SupportInfoFieldType.WorkTime:
        return t("profilePage.supportInfo.workTimeLabel");
      case SupportInfoFieldType.UnemploymentOrSickLeaveDate:
        return t("profilePage.supportInfo.unemploymentOrSickLeaveDateLabel");
      default:
        return null;
    }
  };

  const handleSave = async (value: SupportInfoFieldValueMap[T]) => {
    const labelText = typeLabel();
    try {
      const updatedSupportInfo = getUpdatedSupportInfo(value);
      if (!updatedSupportInfo) return;
      const apiUpdatedSupportInfo = await apiUpdateSupportInfo(updatedSupportInfo);
      dispatch(setSupportInfo(apiUpdatedSupportInfo));
      if (labelText) {
        dispatch(
          showSnackbar({
            message: t("profilePage.supportInfo.saveSuccess", { typeLabel: labelText }),
            severity: "success",
          }),
        );
      }
    } catch (error) {
      if (labelText) {
        dispatch(
          showSnackbar({
            message: t("profilePage.supportInfo.saveError", { typeLabel: labelText }),
            severity: "error",
          }),
        );
      }
    }
  };

  const renderField = () => {
    switch (type) {
      case SupportInfoFieldType.JobSearchLocation:
        return renderTextInput(info as string[], type, displayInfo, handleDialogOpen, t);
      case SupportInfoFieldType.Gender:
      case SupportInfoFieldType.Address:
      case SupportInfoFieldType.PhoneNumber:
      case SupportInfoFieldType.FirstPreferredJob:
      case SupportInfoFieldType.SecondPreferredJob:
      case SupportInfoFieldType.ThirdPreferredJob:
      case SupportInfoFieldType.DesiredJobInFiveYears:
      case SupportInfoFieldType.UnemploymentOrSickLeaveDate:
        return renderTextInput(info as string, type, displayInfo, handleDialogOpen, t);
      case SupportInfoFieldType.WorkTime:
        return renderTextInput(info as number, type, displayInfo, handleDialogOpen, t);
      case SupportInfoFieldType.HasWorkLimitations:
      case SupportInfoFieldType.CanTakeWorkImmediately:
        return (
          <SupportInfoSwitchField
            initialValue={info as boolean}
            handleSave={(val) => handleSave(val as SupportInfoFieldValueMap[T])}
            t={t}
            type={type}
          />
        );
      case SupportInfoFieldType.WorkHours: {
        const workHoursInfo = info as WorkHoursInfo;
        return (
          <WorkHoursField
            dayWorkInitialValue={workHoursInfo.canWorkDaytime}
            eveningWorkInitialValue={workHoursInfo.canWorkEvening}
            nightWorkInitialValue={workHoursInfo.canWorkNighttime}
            handleSave={(val) => handleSave(val as SupportInfoFieldValueMap[T])}
            t={t}
          />
        );
      }
      case SupportInfoFieldType.EducationLevel:
        return (
          <EducationLevelField
            info={info as string}
            handleSave={(val) => handleSave(val as SupportInfoFieldValueMap[T])}
            t={t}
          />
        );
      default:
        return null;
    }
  };

  const exemptTypes = [SupportInfoFieldType.Address, SupportInfoFieldType.PhoneNumber, SupportInfoFieldType.Gender];
  const minWidthForType = exemptTypes.includes(type) ? "auto" : "280px";

  return (
    <Box
      sx={{
        display: forceSingleRow ? "flex" : { xs: "block", sm: "flex" },
        alignItems: forceSingleRow ? "center" : { xs: "flex-start", sm: "center" },
        my: 2,
        fontSize: "1rem",
      }}
    >
      <Box
        sx={{
          minWidth: { xs: "auto", sm: minWidthForType },
          maxWidth: { xs: "100%", sm: minWidthForType },
          textAlign: "left",
          mb: { xs: 1, sm: 0 },
          ...(forceSingleRow && {
            display: "flex",
            alignItems: "center",
          }),
        }}
      >
        {leadingSlot}
      </Box>
      <Box sx={{ flexGrow: 1, textAlign: "left" }}>
        {renderField()}
        <SupportInfoModal
          type={type}
          open={isModalOpen}
          onClose={handleDialogClose}
          getUpdatedSupportInfo={getUpdatedSupportInfo}
          initialValue={info}
        />
      </Box>
    </Box>
  );
};

function renderTextInput(
  info: string | number | string[] | AddressInfo,
  type: SupportInfoFieldType,
  displayInfo: () => string,
  handleDialogOpen: () => void,
  t: TFunction,
) {
  const isInfoIncomplete = (): boolean => {
    switch (type) {
      case SupportInfoFieldType.Address: {
        const address = info as AddressInfo;
        return !address || !address.streetName || !address.zipCode || !address.city;
      }
      case SupportInfoFieldType.JobSearchLocation: {
        const locations = info as string[];
        return !locations || locations.length === 0;
      }
      case SupportInfoFieldType.WorkTime: {
        const numeric = info as number;
        return numeric <= 0;
      }
      default:
        if (typeof info === "string") {
          return info.trim().length === 0;
        } else if (Array.isArray(info)) {
          return info.length === 0;
        }
        return !info;
    }
  };

  const label = () => {
    switch (type) {
      case SupportInfoFieldType.JobSearchLocation:
        return t("profilePage.supportInfo.addJobSearchLocation");
      case SupportInfoFieldType.Gender:
        return t("profilePage.supportInfo.addGender");
      case SupportInfoFieldType.Address:
        return t("profilePage.supportInfo.addAddress");
      case SupportInfoFieldType.PhoneNumber:
        return t("profilePage.supportInfo.addPhoneNumber");
      case SupportInfoFieldType.FirstPreferredJob:
        return t("profilePage.supportInfo.addFirstPreferredJob");
      case SupportInfoFieldType.SecondPreferredJob:
        return t("profilePage.supportInfo.addSecondPreferredJob");
      case SupportInfoFieldType.ThirdPreferredJob:
        return t("profilePage.supportInfo.addThirdPreferredJob");
      case SupportInfoFieldType.DesiredJobInFiveYears:
        return t("profilePage.supportInfo.addDesiredJobInFiveYears");
      case SupportInfoFieldType.WorkTime:
        return t("profilePage.supportInfo.addWorkPercentage");
      case SupportInfoFieldType.UnemploymentOrSickLeaveDate:
        return t("profilePage.supportInfo.addUnemploymentDate");
      default:
        return "";
    }
  };

  return (
    <Box
      onClick={handleDialogOpen}
      data-cy={`add-${type}`}
      sx={{
        textAlign: "left",
        cursor: "pointer",
        "&:hover span": { textDecoration: "underline" },
      }}
    >
      {isInfoIncomplete() ? (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton sx={{ mr: 1, color: "secondary.main" }}>
            <AddCircleOutline sx={{ fontSize: 30 }} />
          </IconButton>
          <Typography variant="subtitle1" sx={{ fontSize: "1rem" }}>
            {label()}
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "&:hover .edit-icon": { visibility: "visible" },
          }}
        >
          <Typography variant="subtitle1" sx={{ mr: 1, fontSize: "1rem" }}>
            {displayInfo()}
          </Typography>
          <IconButton onClick={handleDialogOpen} className="edit-icon" sx={{ visibility: "hidden", p: 0.5 }}>
            <Edit fontSize="small" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}

interface SupportInfoSwitchFieldProps {
  initialValue: boolean;
  handleSave: (value: boolean) => void;
  t: TFunction;
  type: string;
}

const SupportInfoSwitchField: React.FC<SupportInfoSwitchFieldProps> = ({ initialValue, handleSave, type }) => {
  const [checked, setChecked] = useState<boolean>(initialValue);

  return (
    <Checkbox
      sx={{
        fontSize: "1rem",
        "& .MuiSvgIcon-root": {
          fontSize: 24,
        },
      }}
      checked={checked}
      onChange={(event) => {
        setChecked(event.target.checked);
        handleSave(event.target.checked);
      }}
      data-cy={type}
    />
  );
};

interface WorkHoursFieldProps {
  dayWorkInitialValue: boolean;
  eveningWorkInitialValue: boolean;
  nightWorkInitialValue: boolean;
  handleSave: (value: WorkHoursInfo) => void;
  t: TFunction;
}

const WorkHoursField: React.FC<WorkHoursFieldProps> = ({
  dayWorkInitialValue,
  eveningWorkInitialValue,
  nightWorkInitialValue,
  handleSave,
  t,
}) => {
  const [value, setValue] = useState<WorkHoursInfo>({
    canWorkDaytime: dayWorkInitialValue,
    canWorkEvening: eveningWorkInitialValue,
    canWorkNighttime: nightWorkInitialValue,
  });

  const onChange = (key: keyof WorkHoursInfo, checked: boolean) => {
    const newValue = { ...value, [key]: checked };
    setValue(newValue);
    handleSave(newValue);
  };

  return (
    <Box display="flex" alignItems="center" sx={{ gap: 3, fontSize: "1rem" }}>
      <Box display="flex" alignItems="center">
        <Typography variant="subtitle1" sx={{ mr: 1, fontSize: "1rem" }}>
          {t("profilePage.supportInfo.canWorkDaytime")}
        </Typography>
        <Checkbox
          sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
          checked={value.canWorkDaytime}
          onChange={(e) => onChange("canWorkDaytime", e.target.checked)}
          data-cy="canWorkDaytime"
        />
      </Box>
      <Box display="flex" alignItems="center">
        <Typography variant="subtitle1" sx={{ mr: 1, fontSize: "1rem" }}>
          {t("profilePage.supportInfo.canWorkEvening")}
        </Typography>
        <Checkbox
          sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
          checked={value.canWorkEvening}
          onChange={(e) => onChange("canWorkEvening", e.target.checked)}
          data-cy="canWorkEvening"
        />
      </Box>
      <Box display="flex" alignItems="center">
        <Typography variant="subtitle1" sx={{ mr: 1, fontSize: "1rem" }}>
          {t("profilePage.supportInfo.canWorkNighttime")}
        </Typography>
        <Checkbox
          sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
          checked={value.canWorkNighttime}
          onChange={(e) => onChange("canWorkNighttime", e.target.checked)}
          data-cy="canWorkNighttime"
        />
      </Box>
    </Box>
  );
};

interface EducationLevelFieldProps {
  info: string;
  handleSave: (value: string) => void;
  t: TFunction;
}

enum EducationLevels {
  NO_EDUCATION = "noEducation",
  LESS_THAN_SEVEN = "lessThanSevenYears",
  SEVEN_TO_NINE = "sevenToNineYears",
  HIGH_SCHOOL = "highSchool",
  HIGHER_EDU = "higherEducation",
}

const EducationLevelField: React.FC<EducationLevelFieldProps> = ({ info, handleSave, t }) => {
  const [value, setValue] = useState(info);

  return (
    <Select
      value={value}
      onChange={(event: SelectChangeEvent<string>) => {
        const selectedValue = event.target.value;
        setValue(selectedValue);
        handleSave(selectedValue);
      }}
      sx={{ minWidth: 200, fontSize: "1rem" }}
      data-cy="education-level"
    >
      {Object.entries(EducationLevels).map(([key, displayText]) => (
        <MenuItem key={key} value={key} data-cy={`${key}`} sx={{ fontSize: "1rem" }}>
          {t(`profilePage.supportInfo.${displayText}`)}
        </MenuItem>
      ))}
    </Select>
  );
};

const GenderTranslations = {
  MAN: "profilePage.supportInfo.genderMan",
  WOMAN: "profilePage.supportInfo.genderWoman",
  OTHER: "profilePage.supportInfo.genderOther",
};

export default SupportInfoField;

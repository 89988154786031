export type Meeting = {
  id: string;
  startTime: string;
  endTime: string;
  type: MeetingType;
  jobCoachFullName: string;
};

export enum MeetingType {
  Physical = "Physical",
  Virtual = "Virtual",
}

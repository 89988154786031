export interface Language {
  id: string;
  name: string;
  code: string;
  proficiency: string;
}

export const PROFICIENCY_LEVELS = [
  { value: "MISSING", labelKey: "profilePage.language.proficiencyMissing" },
  { value: "A", label: "SFI A" },
  { value: "B", label: "SFI B" },
  { value: "C", label: "SFI C" },
  { value: "D", label: "SFI D" },
  { value: "SAS_G", label: "SAS G" },
  { value: "MOTHER_TONGUE", labelKey: "profilePage.language.proficiencyNative" },
  { value: "FLUENT", labelKey: "profilePage.language.proficiencyFluent" },
  { value: "ADVANCED", labelKey: "profilePage.language.proficiencyAdvanced" },
  { value: "INTERMEDIATE", labelKey: "profilePage.language.proficiencyIntermediate" },
  { value: "BASIC", labelKey: "profilePage.language.proficiencyBasic" },
];

import React, { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  List,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { Close } from "@mui/icons-material";
import { Skill } from "../../types/Skill";
import SkillModal from "./SkillModal";
import { deleteSkill as apiDeleteSkill } from "./ProfilePageRepository";
import { useAppDispatch } from "../../hooks";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { deleteSkill } from "../../reducers/profilePageSlice";
import { useTranslation } from "react-i18next";
import { defaultBorder, defaultBorderColor, maijaBackgroundSecondary } from "../../components/MaijaColors";

const SkillsComponent: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const skills = useSelector((state: RootState) => state.profilePage.skills);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentSkill, setCurrentSkill] = useState<Skill | null>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [skillToDelete, setSkillToDelete] = useState<Skill | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleAddClick = () => {
    setCurrentSkill(null);
    setIsModalOpen(true);
  };

  const handleEditClick = (skill: Skill) => {
    setCurrentSkill(skill);
    setIsModalOpen(true);
  };

  const handleDeleteClick = (skill: Skill) => {
    setSkillToDelete(skill);
    setIsDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    if (skillToDelete) {
      setIsDeleting(true);
      try {
        await apiDeleteSkill(skillToDelete.id);
        dispatch(deleteSkill(skillToDelete.id));
        dispatch(showSnackbar({ message: t("profilePage.skill.deleteSuccess"), severity: "success" }));
      } catch (error) {
        dispatch(showSnackbar({ message: t("profilePage.skill.deleteError"), severity: "error" }));
      } finally {
        setIsDeleting(false);
        setIsDeleteDialogOpen(false);
        setSkillToDelete(null);
      }
    }
  };

  return (
    <Box sx={{ textAlign: "center", p: 2, height: "100%" }}>
      {skills && skills.length > 0 ? (
        <SkillsList skills={skills} onAdd={handleAddClick} onEdit={handleEditClick} onDelete={handleDeleteClick} />
      ) : (
        <EmptyState onAdd={handleAddClick} />
      )}

      <SkillModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        initialSkill={currentSkill}
        existingSkills={skills}
      />

      <Dialog open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
        <DialogTitle>{t("profilePage.skill.confirmDeleteTitle")}</DialogTitle>
        <DialogContent>
          <Typography>
            {t("profilePage.skill.confirmDeleteMessage", {
              skill: skillToDelete?.name,
            })}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteDialogOpen(false)} color="primary">
            {t("generic.cancel")}
          </Button>
          <Button onClick={confirmDelete} color="secondary" variant="contained" disabled={isDeleting}>
            {t("generic.delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

interface SkillsListProps {
  skills: Skill[];
  onAdd: () => void;
  onEdit: (skill: Skill) => void;
  onDelete: (skill: Skill) => void;
}

const SkillsList: React.FC<SkillsListProps> = ({ skills, onAdd, onEdit, onDelete }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <List
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 1,
          mt: 1,
          listStyle: "none",
          m: 0,
        }}
        component="ul"
      >
        {skills.map((skill, index) => (
          <Box
            component="li"
            key={index}
            sx={{
              display: "inline-flex",
              alignItems: "center",
              border: defaultBorder,
              borderColor: defaultBorderColor,
              borderRadius: 5,
              py: 2,
              px: 4,
              gap: 0.5,
              backgroundColor: "background.paper",
              lineHeight: 1,
              "&:hover": {
                backgroundColor: maijaBackgroundSecondary,
                cursor: "pointer",
              },
            }}
            onClick={() => onEdit(skill)}
          >
            <Typography
              variant="body2"
              sx={{
                whiteSpace: "nowrap",
                lineHeight: 1,
                display: "inline-block",
              }}
            >
              {skill.name}
            </Typography>
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                onDelete(skill);
              }}
              sx={{ ml: 1, width: 24, height: 24 }}
            >
              <Close fontSize="small" />
            </IconButton>
          </Box>
        ))}
      </List>
      <Button
        sx={{ mt: 2, minWidth: "30%", alignSelf: "center", width: "auto" }}
        variant="contained"
        color="primary"
        disableRipple
        onClick={onAdd}
        data-cy="add-skill-button"
      >
        {t("profilePage.skill.addButton")}
      </Button>
    </Box>
  );
};

interface EmptyStateProps {
  onAdd: () => void;
}

const EmptyState: React.FC<EmptyStateProps> = ({ onAdd }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        textAlign: "center",
        py: 2,
        px: 3,
      }}
    >
      <Typography variant="h6">{t("profilePage.skill.emptyStateTitle")}</Typography>
      <Typography variant="body1" sx={{ my: 2 }}>
        {t("profilePage.skill.emptyStateMessage")}
      </Typography>
      <Button variant="contained" color="primary" onClick={onAdd} disableRipple data-cy="add-skill-button">
        {t("profilePage.skill.addButton")}
      </Button>
    </Box>
  );
};

export default SkillsComponent;

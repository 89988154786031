import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, TextField, Typography, CircularProgress } from "@mui/material";
import { RootState } from "../../store";
import { updateSupportInfo as apiUpdateSupportInfo, fetchGeneratedIntroduction } from "./ProfilePageRepository";
import { useAppDispatch } from "../../hooks";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { setSupportInfo } from "../../reducers/profilePageSlice";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { useTranslation } from "react-i18next";

const IntroductionComponent: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const supportInfo = useSelector((state: RootState) => state.profilePage.supportInfo);
  const [currentIntroduction, setCurrentIntroduction] = useState<string | null | undefined>(supportInfo?.description);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSave = async () => {
    setLoading(true);
    try {
      if (!supportInfo) {
        return;
      }
      const apiUpdatedSupportInfo = await apiUpdateSupportInfo({
        ...supportInfo,
        description: currentIntroduction,
      });
      dispatch(setSupportInfo(apiUpdatedSupportInfo));
      dispatch(showSnackbar({ message: t("profilePage.introduction.saveSuccess"), severity: "success" }));
    } catch (error) {
      dispatch(showSnackbar({ message: t("profilePage.introduction.saveError"), severity: "error" }));
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateIntroduction = async () => {
    setLoading(true);
    try {
      const generatedIntroduction = await fetchGeneratedIntroduction();
      setCurrentIntroduction(generatedIntroduction);
      dispatch(showSnackbar({ message: t("profilePage.introduction.generateSuccess"), severity: "success" }));
    } catch (error) {
      dispatch(showSnackbar({ message: t("profilePage.introduction.generateError"), severity: "error" }));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", textAlign: "center", p: 2, height: "100%" }}>
      <Typography variant="body1" sx={{ alignSelf: "flex-start" }}>
        {t("profilePage.introduction.subtitle")}
      </Typography>
      <TextField
        multiline
        minRows={10}
        maxRows={10}
        variant="outlined"
        fullWidth
        value={currentIntroduction ?? ""}
        onChange={(e) => setCurrentIntroduction(e.target.value)}
        sx={{ my: 5, flexGrow: 1 }}
        disabled={loading}
      />
      <Box sx={{ flexGrow: 1 }} />

      <Box
        sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%" }}
      >
        <Button
          sx={{ minWidth: "30%", mr: 2, alignSelf: "center", width: "auto" }}
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={loading}
          data-cy="save-introduction-button"
        >
          {t("generic.save")}
        </Button>
        <Button
          sx={{ minWidth: "30%", alignSelf: "center", width: "auto" }}
          variant="outlined"
          color="primary"
          onClick={handleGenerateIntroduction}
          disabled={loading}
          startIcon={loading ? <CircularProgress size={20} /> : <AutoAwesomeIcon />}
          data-cy="generate-introduction-button"
        >
          {loading ? t("profilePage.introduction.generatingButton") : t("profilePage.introduction.generateButton")}
        </Button>
      </Box>
    </Box>
  );
};

export default IntroductionComponent;

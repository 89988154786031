import React from "react";
import { Card, CardActionArea, CardContent, Typography, Box, useTheme, useMediaQuery, Fab } from "@mui/material";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import EditNoteIcon from "@mui/icons-material/EditNote";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import ManualEntryForm from "./ManualEntryForm";
import JobAdLinkForm from "./JobAdLinkForm";
import JobListAdSearchForm from "./JobListAdSearchForm";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { setInputMethod } from "../../../reducers/applicationPageSlice";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export interface ApplicationMethodProps {
  handleGenerateManualApplication: (workplace: string, role: string, description: string) => void;
  handleGenerateJobAdApplication: (link: string) => void;
  handleGenerateJobListAdApplication: (jobAdId: string) => void;
}

type Method = {
  id: number;
  title: string;
  description: string;
  Icon: React.ElementType;
};

const ApplicationMethod: React.FC<ApplicationMethodProps> = (props: ApplicationMethodProps) => {
  const methods: Method[] = [
    { id: 1, title: "pickFromList.title", description: "pickFromList.description", Icon: ManageSearchIcon },
    { id: 2, title: "useJobAdLink.title", description: "useJobAdLink.description", Icon: InsertLinkIcon },
    { id: 3, title: "manualEntry.title", description: "manualEntry.description", Icon: EditNoteIcon },
  ];

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const selectedMethod = useAppSelector((state) => state.applicationPage.inputMethod);

  const handleSelectMethod = (id: number) => {
    dispatch(setInputMethod(id));
  };

  const handleBack = () => {
    dispatch(setInputMethod(undefined));
  };

  const renderInputArea = () => {
    if (!selectedMethod) return null;

    switch (selectedMethod) {
      case 1:
        return <JobListAdSearchForm handleGenerateJobListAdApplication={props.handleGenerateJobListAdApplication} />;
      case 2:
        return <JobAdLinkForm handleGenerateJobAdApplication={props.handleGenerateJobAdApplication} />;
      case 3:
        return <ManualEntryForm handleGenerateManualApplication={props.handleGenerateManualApplication} />;
      default:
        return <Typography>{t("application.method.chooseMethodAbove")}</Typography>;
    }
  };

  const renderMethods = () => {
    if (isSmallScreen && selectedMethod) {
      const chosenMethod = methods.find((m) => m.id === selectedMethod);
      if (!chosenMethod) return null;

      return (
        <>
          <Fab
            color="primary"
            sx={{
              position: "fixed",
              top: 80,
              left: 20,
              width: { xs: 40, sm: 56 },
              height: { xs: 40, sm: 56 },
              minHeight: "unset",
            }}
            onClick={handleBack}
          >
            <ArrowBackIcon sx={{ fontSize: { xs: 20, sm: 24 } }} />
          </Fab>
        </>
      );
    }

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: 4,
        }}
      >
        {methods.map((method) => (
          <Card
            sx={{
              m: isSmallScreen ? 1 : 2,
              width: isSmallScreen ? "100%" : "400px",
              borderRadius: 10,
              border:
                selectedMethod === method.id ? `2px solid ${theme.palette.primary.main}` : "2px solid transparent",
              boxShadow: selectedMethod === method.id ? `0px 0px 7px ${theme.palette.primary.main}` : "none",
              transition: "border 0.3s, box-shadow 0.3s",
            }}
            key={method.id}
            onClick={() => handleSelectMethod(method.id)}
          >
            <CardActionArea sx={{ height: "100%" }}>
              <CardContent>
                <method.Icon
                  sx={{
                    fontSize: 60,
                    margin: "20px auto",
                    display: "block",
                    color: selectedMethod === method.id ? `${theme.palette.primary.main}` : "inherit",
                  }}
                />
                <Typography gutterBottom variant="h5" component="div">
                  {t(`application.method.methods.${method.title}`)}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {t(`application.method.methods.${method.description}`)}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </Box>
    );
  };

  return (
    <Box sx={{ maxWidth: 800, margin: "auto", textAlign: "center", padding: isSmallScreen ? 0 : 8 }}>
      <Typography variant="h3" component="h1" sx={{ mb: 2 }}>
        {t("application.method.title")}
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 8 }}>
        {t("application.method.subtitle")}
      </Typography>
      {renderMethods()}
      <Box>{renderInputArea()}</Box>
    </Box>
  );
};

export default ApplicationMethod;
